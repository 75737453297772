const HPAY_TRANSLATION ={
	en: {
		translation: {
			"__LANGUAGE__": "English",
			"Dashboard": "Dashboard",
			"Welcome!": "Welcome!",
			"You have no company defined. Please first create a company or wait for the access invite!": "You have no company defined. Please first create a company or wait for the access invite!",
			"Confirm": "Confirm",
			"COMPANY": "COMPANY",
			"Sites/Apps/SplitPay receivers": "Sites/Apps/SplitPay receivers",
			"PAYMENT METHODS": "PAYMENT METHODS",
			"TRANSACTIONS": "TRANSACTIONS",
			"SUBSCRIPTIONS": "SUBSCRIPTIONS",
			"PLATFORM MODULES": "PLATFORM MODULES",
			"TICKETS": "TICKETS",
			"HELP": "HELP",
			"DASHBOARD": "DASHBOARD",
			"Please select a company": "Please select a company",
			"ID": "ID",
			"Main site url": "Main site url",
			"Auxiliary site url-s": "Auxiliary site url-s",
			"Company Name": "Company Name",
			"Date": "Date",
			"Company": "Company",
			"--not selected--": "--not selected--",
			"Site": "Site",
			"Select Company": "Select Company",
			"Select Site": "Select Site",
			"Please Select Site": "Please Select Site",
			"Please create a company": "Please create a company",
			"ResNo": "Company Registry ID",
			"TaxNo": "Tax No.",
			"Address": "Address",
			"Country": "Country",
			"Company Details": "Company Details",
			"Details": "Details",
			"Back to list": "Back to list",
			"Update": "Update",
			"Delete": "Delete",
			"Create a company": "Create a company",
			"Too Short!": "Too Short!",
			"Too Long!": "Too Long!",
			"Name is required": "Name is required",
			"Reg Number required": "Reg Number require",
			"Tax Number required": "Tax Number required",
			"Country required": "Country required",
			"Create": "Create",
			"Reset": "Reset",
			"Address is required": "Address is required",
			"Not found": "Not found",
			"Selected site is deleted!": "Selected site is deleted!",
			"The site is added to the company": "The site is added to the company",
			"Please add a site.": "Please add a site.",
			"Sites/Apps/SplitPay receivers": "Sites/Apps/SplitPay receivers",
			"New Site": "New Site...",
			"Add a POS for": "Add a POS for",
			"Save": "Save",
			"Cancel": "Cancel",
			"Site Details": "Site Details",
			"Return to site list": "Return to site list",
			"Site Secret": "Site Secret",
			"Site Key": "Site Key",
			"ReGenerate": "ReGenerate",
			"Action": "Action",
			"Payment Method Name": "Payment Method Name",
			"Payment Methods": "Payment Methods",
			"Add PaymentMethod": "Add PaymentMethod",
			"Selected Payment Methods is added correctly": "Selected Payment Methods is added correctly",
			"New Payment Methods": "New Payment Methods",
			"Payments": "Payments",
			"Payment Method": "Payment Method",
			"Type": "Type",
			"Status": "Status",
			"Please Select Site. so you see it!": "Please Select Site. so you see it!",
			"Transactions": "Transactions",
			"Go Transactions": "Go Transactions",
			"Transactions Details": "Transactions Details",
			"Name": "Name",
			"Amount": "Amount",
			"UID": "UID",
			"Subscriptions": "Subscriptions",
			"Subscriptions Details": "Subscriptions Details",
			"Go Subscriptions": "Go Subscriptions",
			"Payment Methods Details": "Payment Methods Details",
			"GO PaymentMethod": "Go back ...",
			"Platform Modules": "Platform Modules",
			"Ticket": "Ticket",
			"New Ticket": "New Ticket",
			"My Tickets": "My Tickets",
			"Help": "Help",
			"Link": "Link",
			"Description": "Description",
			"Home": "Home",
			"Logout": "Logout",
			"Don’t have an account?": "Don’t have an account?",
			"Get started": "Get started",
			"Seamless site payments | Shipping Automation | Fiscalization": "Seamless site payments | Shipping Automation | Fiscalization",
			"Email must be a valid email address": "Email must be a valid email address",
			"Email is required": "Email is required",
			"Password is required": "Password is required",
			"Email address": "Email address",
			"Password": "Password",
			"Repeat password" : "Repeat password",
			"Remember me": "Remember me",
			"Forgot password?": "Forgot password?",
			"Login": "Login",
			"Sign In FaceBook": "Sign In FaceBoo",
			"Sign In Google": "Sign In Google",
			"Users closed Google Register Window Or Network Error!": "Users closed Google Register Window Or Network Error!",
			"Already have an account?": "Already have an account?",
			"Seamless site payments | Shipping Automation | Fiscalization": "Seamless site payments | Shipping Automation | Fiscalization",
			"OR": "OR",
			"First name required": "First name required",
			"Last name required": "Last name required",
			"ConfrimPassword is required": "ConfrimPassword is required",
			"First name": "First name",
			"Last name": "Last name",
			"Register": "Register",
			"Logo upload": "Logo upload",
			"Localizations": "Localizations",
			"PAYMENT METHOD LOCALIZATIONS": "PAYMENT METHOD LOCALIZATIONS",
			"Add Payment Method Localizations": "Add Payment Method Localizations",
			"Search data...": "Search data...",
			"Localization": "Localization",
			"New Localization": "New Localization",
			"Go Localizations": "Go Localizations",
			"Localization Details": "Localization Details",
			"User": "User",
			"Ticket Name": "Ticket Name",
			"Ticket Details": "Ticket Details",
			"Go Tickets": "Go Tickets",
			"Mark Resolved" : "Mark Resolved",
			"Send" : "Send",
			"New Post" : "New Post",
			"Seamless site payments | Shipping Automation | Fiscalization": "Seamless site payments | Shipping Automation | Fiscalization",
			"Delete company":"Delete company",
			"COMPANY_NAME_EXISTS":"Company with this name is already registered!  You should instead ask the user that registered this company to grant you access.",
			"SITE_EXISTS": "This site is already registered! You should instead ask the user that registered this site to grant you access.",
			"Site Url": "Site Url",
			"Selected POS": "Selected POS",
			"Please Select POS": "Please Select POS",
			"Add a new POS (site or app or split-pay identifier)...": "Add a new POS (site or app or split-pay identifier)...",
			"SITES/APPS/SplitPay-s": "SITES/APPS/SplitPay-s",
			"FISCAL/INTEGRATION MODULES": "FISCAL/INTEGRATION MODULES",
			"SHIPPING METHODS": "SHIPPING METHODS",
			"ORDERS": "ORDERS",
			"SUPPORT TICKETS": "SUPPORT TICKETS",
			"Loading...": "Loading...",
			"From date/time": "From date/time",
			"To date/time": "To date/time",
			"search...": "search...",
			"Load": "Load",
			"Close": "Close",
			"Uid": "Uid",
			"Ident.": "Ident.",
			"Order ident.": "Order ident.",
			"Created On": "Created On",
			"Customer": "Customer",
			"Payment": "Payment",
			"Split pay": "Split pay",
			"Nothing found": "Nothing found",
			"Fiscal/integration methods": "Fiscal/integration methods",
			"Add New Fiscal/Integration Method": "Add New Fiscal/Integration Method",
			"Fiscal/integration method": "Fiscal/integration method",
			"Enabled": "Enabled",
			"List order": "List order",
			"Fallback Of": "Fallback Of",
			"Order": "Order",
			"Payment methods": "Payment methods",
			"Add New Payment Method": "Add New Payment Method",
			"No": "No",
			"POS uri": "POS uri",
			"Additional url-s": "Additional url-s",
			"Merchant POS uid": "Merchant POS uid",
			"HOLESTKey exp.": "HOLESTKey exp.",
			"Web site": "Web site",
			"IOS/Android App": "IOS/Android App",
			"Stand alone split-pay receiver": "Stand alone split-pay receiver",
			"POS: Sites or Apps or SplitPay receivers": "POS: Sites or Apps or SplitPay receivers",
			"Add a new POS": "Add a new POS",
			"POS type": "POS type",
			"comma separated, without www. and https://": "comma separated, without www. and https://",
			"sandbox: key not needed": "sandbox: key not needed",
			"Yes": "Yes",
			"Create new company...": "Create new company...",
			"Administrator": "Administrator",
			"Staff": "Staff",
			"Back to companies list...": "Back to companies list...",
			"Company users": "Company users",
			"Telephone": "Telephone",
			"Role": "Role",
			"Actions": "Actions",
			"Add user": "Add user",
			"Add access for a user": "Add access for a user",
			"Add a user access for the company": "Add a user access for the company",
			"Make sure that a user you are adding access for has an HolestPay account. If not please instruct him/her to create a account first.": "Make sure that a user you are adding access for has an HolestPay account. If not please instruct him/her to create a account first.",
			"Email": "Email",
			"Remove access": "Remove access",
			"platform support": "platform support",
			"yourself": "yourself",
			"Payment Method Configuration": "Payment Method Configuration",
			"Back to site payment methods...": "Back to site payment methods...",
			"LOCALIZATION": "LOCALIZATION",
			"[DEFAULT]": "[DEFAULT]",
			"Add": "Add",
			"Language code": "Language code",
			"2 letters code": "2 letters code",
			"Upload": "Upload",
			"Shipping methods": "Shipping methods",
			"Add New Shipping Method": "Add New Shipping Method",
			"Shipping Method": "Shipping Method",
			"Subscription details...": "Subscription details...",
			"Orders": "Orders",
			"Shipping": "Shipping",
			"Fiscal": "Fiscal",
			"Support tickets": "Support tickets",
			"Create a new support ticket": "Create a new support ticket",
			"Date/Time": "Date/Time",
			"Issue description": "Issue description",
			"Raised by": "Raised by",
			"Concerning company": "Concerning company",
			"Concerning POS": "Concerning POS",
			"Open": "Open",
			"Support ticket": "Support ticket",
			"Ticket time": "Ticket time",
			"Back to support tickets list...": "Back to support tickets list...",
			"SUBJECT": "SUBJECT",
			"Delete Support Ticket": "Delete Support Ticket",
			"Message/Issue Description": "Message/Issue Description",
			"at": "at",
			"Delete message": "Delete message",
			"Select payment method to add...": "Select payment method to add...",
			"Payment Type": "Payment Type",
			"Info": "Info",
			"Recurring support": "Recurring support",
			"Is instant?": "Is instant?",
			"HPay cardform": "HPay cardform",
			"Known banks/financial institutions": "Known banks/financial institutions",
			"Intesa, AIK, Komercijalna(NLB), HALKBANK": "Intesa, AIK, Komercijalna(NLB), HALKBANK",
			"Bilo koja banka u Republici Serbiji": "Bilo koja banka u Republici Serbiji",
			"OTP, Raiffeisen, Unicredit ": "OTP, Raiffeisen, Unicredit ",
			"ALT5PAY": "ALT5PAY",
			"Unos kartice na samom sajtu i COF subskripcije": "Unos kartice na samom sajtu i COF subskripcije",
			"Requirements": "Requirements",
			"E-commerce account with supported bank. Account type must be 3D+API": "E-commerce account with supported bank. Account type must be 3D+API",
			"ADD": "ADD",
			"Generisanje QR koda za placanje iz aplikacije i ekvivalatne uplatnice": "Generisanje QR koda za placanje iz aplikacije i ekvivalatne uplatnice",
			"Racun u bilo kojoj banci u Srbiji": "Racun u bilo kojoj banci u Srbiji",
			"E-commerce account with supported bank": "E-commerce account with supported bank",
			"Mogucnost placanja kriptovalutama za ino kupce. Vi mozete dobiti samo pravi USD|EUR|CAD nikako kriptovalutu (taj deo je u nadleznosti Alt5Pay-a). Zato nije potrebna nikakva poseba licenca i po zakonu ove uplate se sagledavaju na isti način kao i PayPal.": "Mogucnost placanja kriptovalutama za ino kupce. Vi mozete dobiti samo pravi USD|EUR|CAD nikako kriptovalutu (taj deo je u nadleznosti Alt5Pay-a). Zato nije potrebna nikakva poseba licenca i po zakonu ove uplate se sagledavaju na isti naćin kao i PayPal.",
			"Morate imati otvoren nalog na alt5pay.com. Morate imati EUR|USD račun da bi mogli da dovučete uplate. ": "Morate imati otvoren nalog na alt5pay.com. Morate imati EUR|USD račun da bi mogli da dovučete uplate. ",
			"Change password": "Change password",
			"Pick a new password of at least 8 characters, at least one uppercase letter and at least one number": "Pick a new password of at least 8 characters, at least one uppercase letter and at least one number",
			"New password": "New password",
			"Repeat new password": "Repeat new password",
			"Add Biometric/WebAuthN Account Verification": "Add Biometric/WebAuthN Account Verification",
			"Face-Id and fingerprint are biometric credentials and they provide maximal level of reliability for in the process of your identity verification. It's recommended that you use biometric methods when available. Every browser, even on old PC/phone, will enable you to use basic PIN verification.": "Face-Id and fingerprint are biometric credentials and they provide maximal level of reliability for in the process of your identity verification. It's recommended that you use biometric methods when available. Every browser, even on old PC/phone, will enable you to use basic PIN verification.",
			"Verification device selection": "Verification device selection",
			"Use current browser": "Use current browser",
			"Scan this QR code with your mobile phone in order of using your phone as external verification device": "Scan this QR code with your mobile phone in order of using your phone as external verification device",
			"Equivalent url": "Equivalent url",
			"DISCLAMER": "DISCLAMER",
			"does not store or transmit": "does not store or transmit",
			"any of your biometric data like Face-Id or Fingerprint. This data is contained in your device OS, and process of verification itslelf is done by your device/PC. Upon successful verification your device will inform our system that your identity is confirmed by providing matching cryptographic signatures.": "any of your biometric data like Face-Id or Fingerprint. This data is contained in your device OS, and process of verification itslelf is done by your device/PC. Upon successful verification your device will inform our system that your identity is confirmed by providing matching cryptographic signatures.",
			"Account settings": "Account settings",
			"Back to dashboard...": "Back to dashboard...",
			"Add Biometric (Fingerprint, Face-Id or PIN) verification": "Add Biometric (Fingerprint, Face-Id or PIN) verification",
			"Biometric/WebAuthN credentials": "Biometric/WebAuthN credentials",
			"Device type": "Device type",
			"OS": "OS",
			"Browser": "Browser",
			"No biometric/webauthn credetials exists. When identity verification is required HolestPay will revert method of sending you 6 digit security code over your e-mail. It's recommended thet you use more modern and secure biometric/webauthn methods since they don't involve trasmition of security parameters in clear form over channels that don't guarantee absolute security.": "No biometric/webauthn credetials exists. When identity verification is required HolestPay will revert method of sending you 6 digit security code over your e-mail. It's recommended thet you use more modern and secure biometric/webauthn methods since they don't involve trasmition of security parameters in clear form over channels that don't guarantee absolute security.",
			"Passwords do not match": "Passwords do not match",
			"Please enter correct email and password": "Please enter correct email and password",
			"No such user or bad password": "No such user or bad password",
			"Service is currently unavailable. Please try later...": "Service is currently unavailable. Please try later...",
			"Handshake session expired or not found": "Handshake session expired or not found",
			"Data mismatch! Please retry registration from beginning!": "Data mismatch! Please retry registration from beginning!",
			"Password must meet complexity requirements": "Password must meet complexity requirements",
			"Password confirmation mismatch": "Password confirmation mismatch",
			"You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!": "You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!",
			"Access recovery": "Access recovery",
			"Password reset": "Password reset",
			"Enter your email, set a new password and confirm it. Then proceed to the identity verification.": "Enter your email, set a new password and confirm it. Then proceed to the identity verification.",
			"Proceed to identity verification...": "Proceed to identity verification...",
			"Remember me on this browser": "Remember me on this browser",
			"Method UID": "Method UID",
			"Process Group": "Process Group",
			"If multiple methods belong to same process group after one in order generates result rest of them are skipped.":"If multiple methods belong to same process group after one in order generates result rest of them are skipped.",
			"Should be unique! If you need to re-create POS method (because any reason) it's important you keep this value same": "Should be unique! If you need to re-create POS method (because any reason) it's important you keep this value same",
			"ORDER ACTIONS":"ORDER ACTIONS",
			"Standard type is for platforms where you install plugin/module like wooCommerce, OpenCart, Magento, etc..":"Standard type is for platforms where you install plugin/module like wooCommerce, OpenCart, Magento, etc..",
			"Standard": "Standard"
		}
	},
	rs: {
		translation: {
			"__LANGUAGE__": "Srpski",
			"Dashboard": "Komandna tabla",
			"Welcome!": "Dobrodošli!",
			"You have no company defined. Please first create a company or wait for the access invite!": "Molimo da prvo unesete firmu ili sacekajte da vam dodaju pristup za drugu firmu!",
			"Confirm": "Potvrdi",
			"COMPANY": "FIRMA",
			"Sites/Apps/SplitPay receivers": "Sajtovi/Aplikacije/SplitPay",
			"PAYMENT METHODS": "METHODE PLAĆANjA",
			"TRANSACTIONS": "TRANSAKCIJE",
			"SUBSCRIPTIONS": "PRETPLATE",
			"PLATFORM MODULES": "PLATFORMSKI MODULI",
			"TICKETS": "PODRŠKA - ZAHTEVI",
			"HELP": "POMOĆ",
			"DASHBOARD": "KOMANDNA TABLA",
			"Please select a company": "Molimo izaberite firmu",
			"ID": "ID",
			"Main site url": "Glavni url sajta",
			"Additional Site Url-s": "Pomoćni url-ovi sajta",
			"Additional Site Url-s (comma separated)": "Pomoćni url-ovi sajta (odvoji zarezom)",
			"Company Name": "Naziv firme",
			"Date": "Datum",
			"Company": "Firma",
			"--not selected--": "--nije izabrano--",
			"Site": "Web sajt",
			"Select Company": "Izaberite firmu",
			"Please Select Site": "Molimo izaberite Sajt",
			"Select Site": "Izaberite Sajt",
			"Please create a company": "Molimo dodajte firmu prvo",
			"ResNo": "Matični broj",
			"TaxNo": "PIB",
			"Address": "Adresa",
			"Country": "Država",
			"Company Details": "Detalji firme",
			"Company data": "Podaci firme",
			"Details": "Detalji",
			"Back to list": "Povratak na listu firmi",
			"Update": "Sačuvaj",
			"Delete": "Izbriši",
			"Create a company": "Dodaj novu firmu",
			"Too Short!": "Prekratako!",
			"Too Long!": "Suviše dugo!",
			"Name is required": "Ime je obavezno",
			"Reg Number required": "Potreban je registarski broj",
			"Tax Number required": "Poreski broj je obavezan",
			"Country required": "Potrebno je navesti zemlju",
			"Create": "Kreiraj",
			"Reset": "Resetovanje",
			"Address is required": "Adresa je obavezna",
			"Not found": "Nije pronađeno",
			"Selected site is deleted!": "Izabrani sajt je izbrisan!",
			"The site is added to the company": "Novi sajt je dodat za firmu",
			"Please add a site.": "Molimo kreirajte novi sajt.",
			"Sites/Apps/SplitPay receivers": "Sajtovi/Aplikacije/SplitPay",
			"Add a new site/app/split-pay identifier": "Dodaj novi Sajt/Aplikaciju/SplitPay",
			"Add a new POS (site or app or split-pay identifier)...": "Dodaj novi POS (Sajt/Aplikaciju/SplitPay)",
			"Add a POS for": "Dodajte POS za",
			"Save": "Sačuvaj",
			"Cancel": "Poništi",
			"Site Details": "Detalji sajta",
			"Return to site list": "Idi na listu sajtova",
			"Site Secret": "Tajna ključ sajta",
			"Site Key": "Ključ sajta",
			"ReGenerate": "ReGeneriši",
			"Action": "Akcije",
			"Payment Method Name": "Naziv metode plaćanja",
			"Payment Methods": "Načini plaćanja",
			"Add PaymentMethod": "Dodajte metodu plaćanja",
			"Selected Payment Methods is added correctly": "Izabrani načini plaćanja su ispravno dodati",
			"New Payment Methods": "Novi načini plaćanja",
			"Payments": "Plaćanja",
			"Payment Method": "Način plaćanja",
			"Type": "Tip",
			"Status": "Status",
			"Transactions": "Transakcije",
			"Transactions Details": "Detalji transakcije",
			"Name": "Ime",
			"Amount": "Iznos",
			"UID": "UID",
			"Subscriptions": "Pretplate",
			"Subscriptions Details": "Detalji o pretplatama",
			"Payment Methods Details": "Detalji o načiniu plaćanja",
			"Platform Modules": "Platformski Moduli",
			"Ticket": "Zahtev za podrškom",
			"New Ticket": "Novi zahtev",
			"My Tickets": "Moje zahtevi",
			"Help": "Pomoć",
			"Link": "Link",
			"Description": "Opis",
			"Home": "KOMANDNA TABLA",
			"Logout": "Odjaviti se",
			"Don’t have an account?": "Nemate nalog?",
			"Seamless site payments | Shipping Automation | Fiscalization": "Univerzalni platni sistem za pravne subjekte",
			"Email must be a valid email address": "Adresa e-pošte mora biti validna adresa e-pošte",
			"Email is required": "Email je obavezan",
			"Password is required": "Lozinka je neophodna",
			"Email address": "Adresa e-pošte",
			"Password": "Lozinka",
			"Repeat password" : "Potvrda lozinke",
			"Remember me": "Zapamti me",
			"Forgot password?": "Zaboravili ste lozinku?",
			"Login": "Prijavite se",
			"Sign In FaceBook": "Facebook prijava",
			"Sign In Google": "Google prijava",
			"Already have an account?": "Već imate nalog?",
			"Seamless site payments | Shipping Automation | Fiscalization": "Besprekorni prihvat uplata na sajtu | Automatizacija dostave | Fiskalizacija",
			"OR": "ILI",
			"First name required": "Ime je obavezno",
			"Last name required": "Prezime je obavezno",
			"First name": "Ime",
			"Last name": "Prezime",
			"Register": "Registruj se",
			"Logo upload": "Otpremanje logo-a",
			"Localizations": "Lokalizacije",
			"PAYMENT METHOD LOCALIZATIONS": "LOKALIZACIJE METODE PLAĆANjA",
			"Add Payment Method Localizations": "Dodajte lokalizaciju metode plaćanja",
			"Search data...": "Pretraži podatke...",
			"Localization": "Lokalizacija",
			"Localization Details": "Detalji o lokalizaciji",
			"User": "Korisnik",
			"Ticket Name": "Naslov zahteva za prodrškom",
			"Ticket Details": "Detalji o zahtevu za prodrškom",
			"Mark Resolved" : "Označite kao rešeno",
			"Send" : "Pošalji",
			"Delete company":"Brisanje firme",
			"Please confirm you want to delete company": "Molimo potvrdite brisanje firme",
			"You might lose important data releted to this company.": "Možete izgubiti važne podatke i sva postavljena podešavanja u vezi sa ovom firmom",
			"Selected Company":"Firma",
			"Selected Site":"Sajt",
			"Re-generate": "Re-generiši",
			"--company not selected--":"--firma nije odabrana--",
			"--site/app/split-pay not selected--":"--odaberite sajt/aplikaciju/split-pay--",
			"COMPANY_NAME_EXISTS": "Već postoji registrovana firma sa ovim imenom! Zatražite od korisnika koji je kreirao firmu da vam odobri pristup!",
			"SITE_EXISTS": "Ovaj sajt je već registrovan! Zatražite od korisnika koji je kreirao sajt da vam odobri pristup!",
			"Site Url": "Site Url",
			"Selected POS": "Odabrani POS",
			"Please Select POS": "Molimo odaberite POS",
			"SITES/APPS/SplitPay-s": "SAJTOVI/APLIKACIJE/SplitPay",
			"FISCAL/INTEGRATION MODULES": "FISKALNI/INTEGRACIONI MODULI",
			"SHIPPING METHODS": "MODULI ZA ISPORUKU",
			"ORDERS": "NARUDžBINE",
			"SUPPORT TICKETS": "ZAHTEVI TEHNIČKOJ PODRŠĆI",
			"Loading...": "Učitavanje...",
			"From date/time": "Datum/vreme od",
			"To date/time": "Datum/vreme do",
			"search...": "traženje...",
			"Load": "Učitaj",
			"Close": "Zatvori",
			"Uid": "Uid",
			"Ident.": "Ident.",
			"Order ident.": "Narudžbina ident.",
			"Created On": "Vreme kreiranja",
			"Customer": "Kupac",
			"Payment": "Placanje",
			"Split pay": "Split pay",
			"Nothing found": "Ništa nije pronađeno",
			"Fiscal/integration methods": "Fiskalne/integracione metode",
			"Fiscal/integration Methods": "Fiskalne/integracione metode",
			"Add New Fiscal/Integration Method": "Dodaj novu fiskalnu/integracionu metodu",
			"Fiscal/integration method": "Fiskalna/integraciona metoda",
			"Enabled": "Omogućeno",
			"List order": "Indeks redosleda",
			"Fallback Of": "Fallback Of",
			"Order": "Narudžbina",
			"Payment methods": "Metode plaćanja",
			"Add New Payment Method": "Dodaj novu metodu plaćanja",
			"No": "Ne",
			"POS uri": "POS uri",
			"Additional url-s": "Pomoćne url adrese",
			"Merchant POS uid": "UID POS-a trgovca",
			"HOLESTKey exp.": "HOLESTKey istek",
			"Web site": "Web sajt",
			"IOS/Android App": "IOS/Android Applikacija",
			"Stand alone split-pay receiver": "SplitPay - samostani nalog za prijem uplata sa sajta partnera",
			"POS: Sites or Apps or SplitPay receivers": "POS: Sajtovi ili Aplikacije ili samostalni SplitPay prijemnici",
			"Add a new POS": "Dodaj novi POS",
			"POS type": "Tip POS-a",
			"comma separated, without www. and https://": "odvojeno zarezom, bez www. i https://",
			"sandbox: key not needed": "sandbox: ključ nije potreban",
			"Yes": "Da",
			"Create new company...": "Kreiraj novu firmu...",
			"Administrator": "Administrator",
			"Staff": "Osoblje",
			"Back to companies list...": "Povratak na listu firmi...",
			"Company users": "Osoblje firme",
			"Telephone": "Telefon",
			"Role": "Rola",
			"Actions": "Akcije",
			"Add user": "Dodaj korisnika",
			"Add access for a user": "Dodaj pristup za korisnika",
			"Add a user access for the company": "Dodaj pristup korisniku za firmu",
			"Make sure that a user you are adding access for has an HolestPay account. If not please instruct him/her to create a account first.": "Nepohodno je da korisnik kome dajete pristup ima postojeći HolestPay nalog (napomena: sandbox i produkcija ne vide jedno drugo). Ukoliko Vaš sardnik još uvek nama nalog molimo zatražite da ga kreira prvo.",
			"Email": "Email",
			"Remove access": "Ukoloni pristup",
			"HPay Support": "HPay podrška",
			"Yourself": "Vi sami",
			"Payment Method Configuration": "Payment Method Configuration",
			"Back to site payment methods...": "Povratak na listu metoda plaćanja...",
			"LOCALIZATION": "LOKALIZACIJA",
			"[DEFAULT]": "[PODRAZUMEVANO]",
			"Add": "Dodaj",
			"Language code": "Kod jezika",
			"2 letters code": "2 slova koda",
			"Upload": "Otpremi",
			"Shipping methods": "Metode isporuke",
			"Add New Shipping Method": "Dodaj metodu isporuke",
			"Shipping Method": "Metoda isporuke",
			"Subscription details...": "Detalji pretplate...",
			"Orders": "Narudžbine",
			"Shipping": "Isporuka",
			"Fiscal": "Fiskalizaija",
			"Support tickets": "Zahtevi za podrškom",
			"Create a new support ticket": "Napravi zahtev za podrškom",
			"Date/Time": "Datum/Vreme",
			"Issue description": "Opis problema",
			"Raised by": "Prijavio",
			"Concerning company": "Tiče se firme",
			"Concerning POS": "Tiče se POS-a",
			"Open": "Otvori",
			"Support ticket": "Zahtevi za podrškom",
			"Ticket time": "Vreme zahteva",
			"Back to support tickets list...": "Vrati se na listu zahteva za podrškom",
			"SUBJECT": "NASLOV",
			"Delete Support Ticket": "Obriši zahtev za podrškom",
			"Message/Issue Description": "Opis problema/poruka",
			"at": "u",
			"Delete message": "Obriši poruku",
			"Select payment method to add...": "Odaberite platnu metodu za dodavanje...",
			"Payment Type": "Tip plaćanja",
			"Info": "Informacije",
			"Recurring support": "Podrška za pretplate",
			"Is instant?": "Instant plaćanje?",
			"HPay cardform": "HPay unos kartice",
			"Known banks/financial institutions": "Poznate banke/finansijske institucije",
			"Intesa, AIK, Komercijalna(NLB), HALKBANK": "Intesa, AIK, Komercijalna(NLB), HALKBANK",
			"Bilo koja banka u Republici Serbiji": "Bilo koja banka u Republici Srbiji",
			"OTP, Raiffeisen, Unicredit ": "OTP, Raiffeisen, Unicredit ",
			"ALT5PAY": "ALT5PAY",
			"Unos kartice na samom sajtu i COF subskripcije": "Unos kartice na samom sajtu i COF subskripcije",
			"Requirements": "Requirements",
			"E-commerce account with supported bank. Account type must be 3D+API": "E-commerce account with supported bank. Account type must be 3D+API",
			"ADD": "DODAJ",
			"Generisanje QR koda za placanje iz aplikacije i ekvivalatne uplatnice": "Generisanje QR koda za placanje iz aplikacije i ekvivalatne uplatnice",
			"Racun u bilo kojoj banci u Srbiji": "Racun u bilo kojoj banci u Srbiji",
			"E-commerce account with supported bank": "E-commerce account with supported bank",
			"Mogucnost placanja kriptovalutama za ino kupce. Vi mozete dobiti samo pravi USD|EUR|CAD nikako kriptovalutu (taj deo je u nadleznosti Alt5Pay-a). Zato nije potrebna nikakva poseba licenca i po zakonu ove uplate se sagledavaju na isti naćin kao i PayPal.": "Mogucnost placanja kriptovalutama za ino kupce. Vi mozete dobiti samo pravi USD|EUR|CAD nikako kriptovalutu (taj deo je u nadleznosti Alt5Pay-a). Zato nije potrebna nikakva poseba licenca i po zakonu ove uplate se sagledavaju na isti naćin kao i PayPal.",
			"Morate imati otvoren nalog na alt5pay.com. Morate imati EUR|USD račun da bi mogli da dovučete uplate. ": "Morate imati otvoren nalog na alt5pay.com. Morate imati EUR|USD račun da bi mogli da dovučete uplate. ",
			"Change password": "Promena lozinke",
			"Pick a new password of at least 8 characters, at least one uppercase letter and at least one number": "Izaberite novu lozinku od najmanje 8 znakova, najmanje jednog velikog slova i najmanje jednog broja",
			"New password": "Nova lozinka",
			"Repeat new password": "Ponovi novu lozinku",
			"Add Biometric/WebAuthN Account Verification": "Dodaj Biometrisku/WebAuthN verifikaciju naloga",
			"Face-Id and fingerprint are biometric credentials and they provide maximal level of reliability for in the process of your identity verification. It's recommended that you use biometric methods when available. Every browser, even on old PC/phone, will enable you to use basic PIN verification.": "Face-Id i otisak prsta su pravi biometrijske akreditivi i pružaju maksimalan nivo pouzdanosti u procesu verifikacije vašeg identiteta. Preporučuje se da koristite biometrijske metode kada su dostupne. Svaki pretraživač, čak i na starom računaru/telefonu, omogućiće vam da koristite osnovnu verifikaciju PIN-om.",
			"Verification device selection": "Odabir verifikacionog uređaja",
			"Use current browser": "Koristi trenutni pretraživač",
			"Scan this QR code with your mobile phone in order of using your phone as external verification device": "Skenirajte ovaj QR kod svojim mobilnim telefonom kako biste svoj telefon koristili kao spoljni uređaj za verifikaciju",
			"Equivalent url": "Ekvivalentni link",
			"DISCLAMER": "ODRICANJE OD ODGOVORNOSTI",
			"does not store or transmit": "ne skladišti ili transmituje",
			"any of your biometric data like Face-Id or Fingerprint. This data is contained in your device OS, and process of verification itslelf is done by your device/PC. Upon successful verification your device will inform our system that your identity is confirmed by providing matching cryptographic signatures.": "bilo koji od vaših biometrijskih podataka kao što su Face-Id ili otisak prsta. Ovi podaci se nalaze u OS vašeg uređaja, a sam proces verifikacije obavlja vaš uređaj/računar. Nakon uspešne verifikacije, vaš uređaj će obavestiti naš sistem da je vaš identitet potvrđen pružanjem odgovarajućih kriptografskih potpisa.",
			"Account settings": "Podešavanja naloga",
			"Back to dashboard...": "Povratak na početnu stranu...",
			"Add Biometric (Fingerprint, Face-Id or PIN) verification": "Dodaj Biometrisku (Otisak prsta, Face-Id ili PIN) verifikaciju",
			"Biometric/WebAuthN credentials": "Biometriski/WebAuthN kredencijali",
			"Device type": "Tip uređaja",
			"OS": "OS",
			"Browser": "Pretraživač",
			"No biometric/webauthn credetials exists. When identity verification is required HolestPay will revert method of sending you 6 digit security code over your e-mail. It's recommended thet you use more modern and secure biometric/webauthn methods since they don't involve trasmition of security parameters in clear form over channels that don't guarantee absolute security.": "Ne postoje biometrijski/WebAuthN akreditivi. Kada je potrebna verifikacija identiteta, HolestPay će primeniti metodu slanja 6-cifrenog sigurnosnog koda preko e-mail-a. Preporučuje se da koristite modernije i bezbednije biometrijske/WebAuthN metode jer one ne uključuju prenošenje bezbednosnih parametara u izvornom obliku preko kanala koji ne garantuju apsolutnu bezbednost.",
			"Passwords do not match": "Lozinke se ne pokapaju",
			"Please enter correct email and password": "Molimo unesite ispravni e-mail i lozinku",
			"No such user or bad password": "Korisnik ne postoji ili pogrešna lozinka",
			"Service is currently unavailable. Please try later...": "Servis je trenutno nedostupan. Molimo probajte kasnije...",
			"Handshake session expired or not found": "Verifikaciona sesija je istekla ili nije nađena",
			"Data mismatch! Please retry registration from beginning!": "Neslaganje podataka! Molimo probajte registraciju od početka!",
			"Password must meet complexity requirements": "Lozinka mora zadovoljiti kriterijume kompleksnosti",
			"Password confirmation mismatch": "Neslaganje potrvde lozinke",
			"You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!": "Privremeno ste blokirani zbog velikog broja zahteva u kratakom vremenskom periodu. Probajte za nekoliko minuta!",
			"Access recovery": "Povratak pristupa",
			"Password reset": "Resetovanje lozinke",
			"Enter your email, set a new password and confirm it. Then proceed to the identity verification.": "Unesite svoju e-poštu, postavite novu lozinku lozinku i potvrdite je. Zatim nastavite na korak verifikacije identiteta.",
			"Proceed to identity verification...": "Nastavi na verifikaciju identiteta...",
			"Remember me on this browser": "Zapamti me na ovom pretraživaču",
			"Upload blocked!":"Otpremanje blokirano!",
			"Site payment method data saved!": "Podaci platne metode sačuvani!",
			"Updated!": "Sačuvano!",
			"Please set a company logo!":"Molimo otpremite logo sliku!",
			"Please enter at least 8 characters": "Molimo unsetite bar 8 karaktera",
			"Please enter at least 1 number": "Molimo unsetite bar jednu cifru",
			"Please enter at least 1 lowercase letter": "Molimo unsetite bar jedno malo slovo",
			"Please enter at least 1 uppercase letter": "Molimo unsetite bar jedno veliko slovo",
			"Please enter at least 1 special character": "Molimo unsetite bar jedan specijalni karakter",
			"Transaction details...": "Detalji transakcije...",
			"ACTIONS": "AKCIJE",
			"Support Ticket Details": "Detalji zahteva za podrškom",
			"This support ticket is resolved": "Ovaj zahtev je označen kao razrešen",
			"enter valid url": "unesite pravilan link",
			"enter url": "unesite link",
			"Auxiliary site url-s": "Pomoćni url-ovi sajta",
			"NOTE: for registered sites and apps you can use site UID as split-pay identifier. You need a stand alone split-pay POS only when you don't have your site/app and you need to receive payments only through purhases over your partner's site(s).": "NAPOMENA: za registrovane sajtove i aplikacije možete koristiti UID sajta kao identifikator SplitPay-a. Potreban vam je samostalni POS sa UID-om za SplitPay samo kada nemate svoju web prodavnicu/aplikaciju te primate uplate samo putem kupovine preko sajta/sajtova partnera.",
			"POS Configuration": "POS Konfiguracija",
			"type": "tip",
			"Back to sites list...": "Povratak na listu sajtova",
			"URI/Indentifier": "URI/Identifikator",
			"Additional Site Url-s (one per line)": "Pomoćni url-ovi sajta (jedan po liniji)",
			"additional site url-s (one per line)": "pomoćni url-ovi sajta (jedan po liniji)",
			"Merchant POS UID": "POS UID Trgovca",
			"POS Secret Key": "POS Tajni Ključ",
			"Prefered exchange rate source": "Prefereca kursne liste",
			"Exchange rate correction % (middle)": "Korekcija srednjeg kursa u %",
			"HOLEST Key (purchase key)": "HOLEST Ključ (dobija se pri kupovini)",
			"Key expires at": "Ključ ističe",
			"Shipping Method Configuration": "Konfiguracija metode isporuke",
			"Back to site shipping methods...": "Povratak na listing medoda isporuke...",
			"Fallback of": "Fallback of",
			"none": "nije odabrano",
			"Order details...": "Detalji narudžbine...",
			"Raise a new support ticket": "Kreiraj zahtev za podrškom",
			"Support tickets are way to make sure our support receives your support request (since email can be unreliable sometimes). Please allow our team at least 24h to respond.": "Zahtevi za podrškom garantuju da će naš tim primiti Vašu poruku (pošto e-pošta ponekad može biti nepouzdana). Dozvolite našem timu 24 sata da odgovori.",
			"You will be able to describe your problem after ticket creation.": "Detaljan opis problema upisaćete posle kreiranja zahteva.",
			"Please name your issue": "Molimo naslovite Vaš problem",
			"Concerning Company": "Tiče se firme",
			"Fiscal/integration method configuration": "Konfiguracija fiskalne/integracione metode",
			"Back to site fiscal/integration methods...": "Vrati se na listu fiskalnih/integracionih metoda...",
			"Select shipping method to add...": "Odaberite fiskalnu medodu za dodavanje...",
			"Select fiscal/integration method to add...": "Select fiscal/integration method to add...",
			"Please enter valid email address": "Molimo unesite ispravnu e-mail adresu",
			"You can close this window": "Možete zatvoriti ovu stranicu/prozor",
			"Identity verification required": "Potrebna je verifikacija identiteta",
			"Biometric/WebAuthN verification device selection": "Odabor uređaja za Biometrisku/WebAuthN verifikaciju",
			"Scan this QR code with your mobile phone if you are using your phone as external verification device": "Skenirajte ovaj QR kod svojim mobilnim telefonom ako koristite telefon kao spoljni uređaj za verifikaciju",
			"Try different identity verification method...": "Pokušajte dugu metodu verifikacije identiteta...",
			"E-mail address verification pending...": "Čeka se na potvrdu identiteta preko koda iz e-mail poruke...",
			"Please check your mailbox for 6-digit security code we sent you. Don't forget to check SPAM/Trash/Junk folders also if you don't find a mail message containing security code in your primary inbox.": "Proverite da li u Vašem poštanskom sandučetu postoji šestocifreni sigurnosni kod koji smo vam poslali. Ne zaboravite da proverite fascikle SPAM/Trash/Junk takođe ako ne pronađete e-poruku koja sadrži sigurnosni kod u svom primarnom prijemnom sandučetu.",
			"SECURITY CODE": "SIGURNOSNI KOD",
			"Cancel and return to the registration form...": "Odustani i vrati se na registracionu formu...",
			"Account data": "Podaci naloga",
			"Biometric/WebAuthN Identity Protection": "Biometriska/WebAuthN zaštita Identiteta",
			"Recommended": "Preporučeno",
			"Upon registration setup biometric/webauthn credentials": "Po registraciji postavi bimetriske/webauthn kredencijale (otisak prsta, face-id...)",
			"Password updated": "Lozinka je promenjena",
			"Error - password refused": "Greška - lozinka nije prohvaćena",
			"Error - in password update request": "Greška pri zahtevu za promenu lozinke",
			"Error - could not update the password": "Greška - lozinka nije mogla biti promenjena",
			"Data updated": "Podaci su ažurirani",
			"Error - could not update the data": "Greška podaci nisu mogli biti promenjeni",
			"Error - refused": "Greška - zahtev odbijen",
			"Error - in request": "Greška u zahtevu",
			"Error - could not obtain credential parameters": "Greška - parametri kredencijala nisu dostupni",
			"Credentials over external device added successfully": "Kredencijali preko eksternog uređaja su uspešno dodati",
			"Could not obtain credentials!": "Greška - kredencijali nisu dostupni!",
			"Could not load payment methods definition": "Definicije platnih metoda nisu učitane!",
			"Could not load shipping methods definition": "Definicije metoda isporuke nisu učitane!",
			"Could not load fiscal/integration methods definition": "Definicije fiskalnih/integracionih metoda nisu učitane!",
			"Error during data loading!": "Greška pri učitavanju podataka!",
			"Please select a POS!": "Molimo daberite POS!",
			"You must select a company so you could raise a new ticket concerning it": "Molimo odaberite firmu u vezi koje šaljete pitanje/zahtev",
			"Message deleted": "Poruka obrisna",
			"Error: message could not be deleted!": "Greška: poruka nije mogla da se obriše!",
			"Error: ticket could not be closed!": "Greška: zahtev nije mogao da se zatvori!",
			"Error loaging support ticket list": "Greška pri učitavanju liste zahteva za podršku",
			"Error during site delete": "Greška prilikom brisanja sajta",
			"Error during POS delete": "Greška prilikom brisanja POS-a",
			"Please correct your input!": "Ispravite svoj unos!",
			"The POS is added successfully!": "POS je uspešno dodat!",
			"Error during POS add": "Greška tokom POS dodavanja",
			"Please add a POS - Site, App or SplitPay.": "Dodajte POS - sajt, aplikaciju ili SplitPay.",
			"Error during data loading": "Greška tokom učitavanja podataka",
			"Shipping method is deleted": "Način isporuke je izbrisan",
			"Error: Shipping method could not be deleted!": "Greška: način isporuke nije moguće izbrisati!",
			"Error loading POS shipping methods!": "Greška pri učitavanju POS metoda isporuke!",
			"Site shipping method data saved!": "Podaci o načinu isporuke na sajtu su sačuvani!",
			"Error saving site shipping method data": "Greška pri čuvanju podataka o načinu isporuke sajta",
			"Bad language code. Please use 2-letters code, optionally with extension -cyr!": "Loš kod jezika. Molimo koristite kod od 2 mala slova, opciono sa ekstenzijom -cyr!",
			"Error loading site shipping method data": "Greška pri učitavanju podataka o načinu isporuke sajta",
			"Please select site...": "Molimo izaberite sajt...",
			"Payment method is deleted": "Način plaćanja je izbrisan",
			"Error: Payment method could not be deleted!": "Greška: Način plaćanja nije moguće izbrisati!",
			"Error loading site payment methods!": "Greška pri učitavanju načina plaćanja sajta!",
			"Error saving site payment method data": "Greška pri čuvanju podataka o načinu plaćanja sajta",
			"Error during support ticket creation": "Greška tokom kreiranja tiketa za podršku",
			"Please Select Company": "Molimo izaberite kompaniju",
			"Fiscal/integration method is deleted": "Fiskalni/integracioni metod je izbrisan",
			"Error: fiscal/integration method could not be deleted!": "Greška: Fiskalni metod nije moguće izbrisati!",
			"Error loading site fiscal/integration methods!": "Greška pri učitavanju fiskalniһ metoda sajta!",
			"Site fiscal/integration method data saved!": "Podaci o fiskalnoj metodi sajta su sačuvani!",
			"Error saving site fiscal/integration method data": "Greška pri čuvanju podataka o fiskalnoj metodi sajta",
			"Error loading site fiscal/integration method data": "Greška pri učitavanju podataka o fiskalnoj/integracionoj metodi sajta",
			"Error during adding of this shipping method!": "Greška prilikom dodavanja ovog načina isporuke!",
			"Error: could not add this shipping method at the moment!": "Greška: trenutno nije moguće dodati ovaj način isporuke!",
			"The Shipping Methods is exist in this site": "Metode isporuke postoje na ovom sajtu",
			"Error during adding of this payment method!": "Greška prilikom dodavanja ovog načina plaćanja!",
			"Error: could not add this payment method at the moment!": "Greška: trenutno nije moguće dodati ovaj način plaćanja!",
			"Error during adding of this fiscal/integration method!": "Greška prilikom dodavanja ovog fiskalnog/integracionog metoda!",
			"Error: could not add this fiscal/integration method at the moment!": "Greška: trenutno nije moguće dodati ovu fiskalnu metodu!",
			"The fiscal/integration methods is exist in this site": "Fiskalni/integracioni metodi postoje na ovom sajtu",
			"Please set a company logo": "Postavite logo kompanije",
			"The company created successfully!": "Kompanija je uspešno dodata!",
			"Error during company create": "Greška tokom kreiranja kompanije",
			"Please use .jpg or .png image": "Molimo koristite .jpg ili .png sliku",
			"Error on data load!": "Greška pri učitavanju podataka!",
			"Error on data update!": "Greška pri ažuriranju podataka!",
			"Error on data delete!": "Greška pri brisanju podataka!",
			"Error during access removal": "Greška prilikom uklanjanja pristupa",
			"User with given email was not found": "Korisnik sa datom e-poštom nije pronađen",
			"You can not manage yourself": "Ne možete upravljati sobom",
			"Error adding access for user": "Greška pri dodavanju pristupa za korisnika",
			"Error changing user role": "Greška pri promeni uloge korisnika",
			"External-device identity verification completed!": "Verifikacija identiteta spoljnog uređaja je završena!",
			"Could not complete identity verification over external-device": "Nije moguće završiti verifikaciju identiteta preko spoljnog uređaja",
			"Biometric/WebAuthN verification failed. You can try other verfication method...": "Biometrijska/VebAutһN verifikacija nije uspela. Možete isprobati drugi metod verifikacije...",
			"Could not obtain credentials! Try reverting to verification over e-mailed security code...": "Nije moguće dobiti akreditive! Pokušajte da se vratite na verifikaciju preko bezbednosnog koda poslatog e-poštom...",
			"Biometric/WebAuthN credentials approval on external device...": "Odobrenje biometrijskiһ/VebAutһN akreditiva na spoljnom uređaju...",
			"Biometric/WebAuthN authorization over an external device...": "Biometrijska/WebAutһN autorizacija preko spoljnog uređaja...",
			"Non-valid/unrecognised request!": "Nevažeći/neprepoznat zaһtev!",
			"New order": "Nova narudžbina",
			"New subscription": "Nova pretplata",
			"Please add a POS.": "Molimo dodajte POS.",
			"Identity verification": "Verifikacija identiteta",
			"Error! Please try again or later...": "Greška! Pokušajte ponovo ili kasnije...",
			"Could not perform Biometric/WebAuthN verification!": "Nije moguće izvršiti biometrijsku/WebAutһN verifikaciju!",
			"Account data is accepted. You must verify your email address before we finalize you account creation. Please check you email for security code we sent you.": "Podaci o nalogu su priһvaćeni. Morate da verifikujete svoju adresu e-pošte pre nego što završimo kreiranje vašeg naloga. Proverite svoju e-poštu za bezbednosni kod koji smo vam poslali.",
			"Bad response please retry or try later...": "Loš odgovor, pokušajte ponovo ili pokušajte kasnije...",
			"first name is required": "ime je obavezno",
			"last name is required": "prezime je obavezno",
			"email is required": "e-mail je obavezan",
			"password is required": "pristupna šifra je obavezna",
			"confrimation of password is required": "potrebna je potvrda lozinke",
			"Customer support email": "Vaš e-mail za poršku kupcima",
			"Customer support telephone": "Vaš telefon za poršku kupcima",
			"Customer support notes": "Vaše napomene vezano za poršku kupcima",
			"enter notes about your customer support": "unesite napomene vezano za poršku kupcima",
			"Your payment terms link": "Link do Vaše strane sa uslovima kupovine",
			"HolestPay": "HolestPay",
			"Remove biometric/webauthn credential": "Uklonite biometrijske/vebauthn akreditive",
			"Please confirm you want remove credential": "Potvrdite da želite da uklonite akreditive",
			"Remove": "Ukloni",
			"Delete support ticket message": "Izbrišite poruku za podršku",
			"Please confirm you want remove message": "Potvrdite da želite da uklonite poruku",
			"Mark resolved": "Označi kao razrešeno",
			"Please confirm you want mark this ticket as resolved": "Potvrdite da želite da označite ovj zahtev kao rešen",
			"Close ticket": "Zatvori zahtev",
			"Closed": "Zatvoreno",
			"Issue": "Opis problema/Pitanje",
			"IOS/Android bundle id-s - comma separated": "ID-jevi identifikatora aplikacije za iOS/Android - odvojeni zarezima",
			"Idenificator like 'install-air-conditioner' or 'donation-j.doe' or 'joes-currier'": "Identifikator poput „instalacija-klima-uređaja“ ili „donation-j.doe“ ili „pera-isporuka-i-montaza“",
			"Delete site": "Obriši sajt",
			"Please confirm you want to delete site": "Potvrdite da želite da izbrišete sajt",
			"You might lose important data releted to this site.": "Možda ćete izgubiti važne podatke koji se odnose na ovaj sajt.",
			"not needed for the sandbox mode": "nije potrebno za režim sandbox-a",
			"key you bought on ecommerce.holest.com": "ključ koji ste kupili na https://ecommerce.holest.com",
			"Regenerate secret key": "Regenerišite tajni ključ",
			"Please confirm you want to regenerate POS secret key": "Potvrdite da želite da ponovo generišete POS tajni ključ",
			"You will lose connection with this POS untill you update secret key with it.": "Izgubićete vezu sa ovim POS dok ne ažurirate tajni ključ na njemu.",
			"Remove shipping method": "Uklonite način isporuke",
			"Please confirm you want remove shipping method": "Potvrdite da želite da uklonite način isporuke",
			"You may lose important data by deleting this method.": "Možete izgubiti važne podatke brisanjem ovog metoda.",
			"Remove localization": "Uklonite lokalizaciju",
			"Please confirm you want remove localization": "Potvrdite da želite da uklonite lokalizaciju",
			"No selected": "Nije izabrano",
			"Remove payment method": "Uklonite način plaćanja",
			"Please confirm you want remove payment method": "Potvrdite da želite da uklonite način plaćanja",
			"Remove fiscal/integration method": "Uklonite fiskalni/integracioni metod",
			"Please confirm you want remove fiscal/integration method": "Potvrdite da želite da uklonite fiskalni/integracioni metod",
			"access to": "pristup",
			"Set role: staff": "Rola -> osoblje",
			"Set role: admin": "Rola -> admin",
			"Set role: owner": "Rola -> vlasnik",
			"Please confirm you want change role of": "Molimo potvrdite promenu role za",
			"to" :"u",
			"Set new role":"Postavi novu rolu",
			"Only one user can be POS 'creator' therefore current 'creator' will became 'admin'.": "Samo jedan nalog može biti vlasnik POS-a pa će zato trenutni 'vlasnik' postati 'admin'.",
			"Error during changing of ther user role": "Greška pri promeni role naloga za POS",
			"Change POS user role": "Promena role naloga za POS",
			"staff": "osoblje",
			"admin": "admin",
			"creator": "vlasnik",
			"hpay-support":"hpay podrška",
			"Remove user access": "Uklonite pristup korisnika",
			"Please confirm you want to remove": "Potvrdite da želite da uklonite",
			"Remove Access": "Uklonite pristup",
			"Please check you email for security code we sent you.": "Proverite svoju e-poštu za bezbednosni kod koji smo vam poslali.",
			"Reverting to verification over e-mailed security code...": "Vraćanje na verifikaciju preko bezbednosnog koda poslatog e-poštom...",
			"enter valid email": "Unesite ispravnu e-mail",
			"Please correct your input": "Ispravite svoj unos",
			"Bad email address": "Loša adresa e-pošte",
			"Bad first or last name": "Loše ime ili prezime",
			"Specified email already exists": "Navedena adresa e-pošte već postoji",
			"too short?": "prekratko?",
			"too long?": "predugo?",
			"Waiting for authorization...": "Čekanje na autorizaciju...",
			"Company selection/registration...": "Izbor/registracija firme...",
			"Site selection/registration...": "Izbor/registracija sajta...",
			"POS was updated correctly!": "POS je uspešno ažuriran!",
			"Error in site update request!": "Greška pri zahtevu ažuriranja!",
			"and choose...": "i odaberi...",
			"POS type is not an site. You can not connect site to different POS types!": "Odabrani POS nije tipa sajt. Ne možete povezati sajt sa drugim tipovima POS-a.",
			"Connected!":"Povezano!",
			"ACTIVATED":"AKTIVIRANO",
			"HOLEST PURCHASE KEY WAS NOT ACCEPTED!": "HOLEST KLJUČ PROIZVODA NIJE PRIVAĆEN!",
			"Buy a new key...": "Kupi novi kljuć...",
			"Fiscal/integration/e-invoice": "Fiskal/integracije/e-fakture",
			"Site to be connected must be main url or one of auxiliary urls for the selected POS!":"Sajt koji se povezuje mora biti glavni uri ili jedan od pomoćnih URI-ja za izabrani POS!",
			"bad value!": "loša vrednost!",
			"Shipping Methods": "Metode Isporuke",
			"fiscal/e-invoice tax system integration, embedded devices (like carwash coin machines) or external system notifications (production line intstructions)": "integracija sa poreskim sistemom fiskal/e-faktura, notifikacije za specijalizovane uređaje (poput masina za žetone kod autoperionica) ili notifikacija ka drugim sistemima (npr. pošalji nalog fabrici)",
			"courier systems api integration": "API integracije sa sistemima kurirskih službi",
			"banks/financial instutions payments integrations": "integracije sa platim sistemima banaka i drugih finansijskih institucija",
			"Instant payment/shipping/fiscal/integation notification url": "Link za instant notifikacije - plaćanje/isporuka/fiskal/integracije",
			"Fixed language (for customers)": "Fiksirani jezik (za kupce)",
			"Payment vault token saving policy (when available)": "Polisa čuvanja platnog tokena (kad je dostupno)",
			"Leave as POS requested": "Ostavi kako je POS-a zahtevao",
			"Never save": "Nikad ne čuvaj",
			"Force required": "Postavi kao obavezno",
			"Method UID": "UID metode",
			"Process Group": "Procesna grupa",
			"If multiple methods belong to same process group after one in order generates result rest of them are skipped.": "Ukoliko više metoda pripada istoj procesnoj grupi, i kada jedna u redosledu generise rezultat izvršenje ostalih se preskače",
			"Should be unique! If you need to re-create POS method (because any reason) it's important you keep this value same": "Treba biti unikatna vrednost! Ukoliko morate da re-kreirate metodu za POS (iz bilo kog razloga) važno je da ova vrednost ostane ista",
			"ORDER ACTIONS":"AKCIJE ZA NARUDž.",
			"Show path": "Pokaži putanju",
			"Go to": "Idi na",
			"Go to first page": "Idi na prvu stranicu",
			"Go to last page": "Idi na poslednju stranicu",
			"Go to next page": "Idi na sledeću stranicu",
			"Go to previous page": "Idi na prethodnu stranicu",
			"page": "stranicu",
			"of": "od",
			"Empty": "Prazno",
			"Clear": "Briši",
			"Close": "Zatvori",
			"Loading…": "Učitavanje…",
			"No options": "Nema opcija",
			"Open": "Otvori",
			"Pagination navigation": "Navigacija po stranicama",
			"Rows per page:": "Redova po stranici:",
			"Company Warehouses": "Magacini Firme",
			"Identifier": "Identifikator",
			"Postal Code":"Poštanski broj",
			"Street Name":"Naziv ulice",
			"Street Number":"Broj u ulici",
			"important if you use shipping":"bitno ako koritite module za isporuku",
			"Default":"Podrazumevano",
			"Disable HPay Notifications":"Isključi HPay Notifikacije",
			"Enter current password":"Unesite trenutnu lozinku",
			"Current password": "Trenutna lozinka",
			"If your site/POS can handle all customer messaging. Note that some messages are required by law!":"Ako samo prodajno mesto može poslati sve poruke kupcima. NAPOMENA: neke poruke su obavezne po zakonu!",
			"Use your own SMTP to send customer notifications":"Koristi svoj SMTP za email notifikacije kupcima",
			"Enable if you want to deliver HPay customer mails from your SMTP server. If send fails it will revert to HPay SMTP!":"Uključi ako želiš da se HPay notifikacije za kupce šalju sa tvog SMTP servera. Ako slanje ne uspe mailovi će biti poslati preko HPay SMTP-a",
			"Send test mail to":"Pošalji test mail na adresu",
			"TEST SMTP SEND":"POŠALJI TEST MAIL",
			"Enter proper email address!":"Unesi ispravnu e-mail adresu!",
			"SMTP Password":"SMTP Lozinka",
			"SMTP Username":"SMTP Korisničko Ime",
			"SMTP Security Protocol":"SMTP Sigurnosni Protokol",
			"SMTP From Name": "SMTP Naziv Pošiljaoca",
			"Enter proper SMTP settings!":"Unsite ispravna SMTP podešavanja!",
			"Error in test mail sending!":"Greška pri slanju test email poruke",
			"Test mail was sent however you must confirm successful reception by checking test address inbox!":"Test email poruka je poslata ali morate takođe proveriti da je prijem bio uspešan u inbox-u test mail adrese!",
			"Standard type is for platforms where you install plugin/module like wooCommerce, OpenCart, Magento, etc..": "Standardni tip je za platforme gde instalate plugin/modul , npr. wooCommerce, OpenCart, Magento, itd..",
			"Standard":"Standardno",
			
		}
	},
	mk:{
		translation: {
			"__LANGUAGE__": "Makedonski",
			"Dashboard": "Контролна табла",
			"Welcome!": "Добредојдовте!",
			"You have no company defined. Please first create a company or wait for the access invite!": "Ве молиме прво да внесете компанија или почекајте да ви додадат пристап за друга компанија!",
			"Confirm": "Потврди",
			"COMPANY": "КОМПАНИЈА",
			"Sites/Apps/SplitPay receivers": "Сајтови/Апликации/SplitPay",
			"PAYMENT METHODS": "МЕТОДИ НА ПЛАЌАЊЕ",
			"TRANSACTIONS": "ТРАНСАКЦИИ",
			"SUBSCRIPTIONS": "ПРЕТПЛАТИ",
			"PLATFORM MODULES": "ПЛАТФОРМСКИ МОДУЛИ",
			"TICKETS": "ПОДДРШКА - БАРАЊА",
			"HELP": "ПОМОШ",
			"DASHBOARD": "КОНТРОЛНА ТАБЛА",
			"Please select a company": "Ве молиме изберете компанија",
			"ID": "ID",
			"Main site url": "Главна url адреса на сајтот",
			"Additional Site Url-s": "Помошни url адреси на сајтот",
			"Additional Site Url-s (comma separated)": "Помошни url адреси на сајтот (одделени со запирка)",
			"Company Name": "Име на компанија",
			"Date": "Датум",
			"Company": "Компанија",
			"--not selected--": "--не е избрано--",
			"Site": "Веб сајт",
			"Select Company": "Изберете компанија",
			"Please Select Site": "Ве молиме изберете Сајт",
			"Select Site": "Изберете Сајт",
			"Please create a company": "Ве молиме додадете компанија прво",
			"ResNo": "Матичен број",
			"TaxNo": "Даночен број",
			"Address": "Адреса",
			"Country": "Држава",
			"Company Details": "Детали за компанијата",
			"Company data": "Податоци за компанијата",
			"Details": "Детали",
			"Back to list": "Враќање на листата на компании",
			"Update": "Зачувај",
			"Delete": "Избриши",
			"Create a company": "Додај нова компанија",
			"Too Short!": "Премногу кратко!",
			"Too Long!": "Премногу долго!",
			"Name is required": "Името е задолжително",
			"Reg Number required": "Потребен е регистарски број",
			"Tax Number required": "Даночниот број е задолжителен",
			"Country required": "Потребно е да се наведе земја",
			"Create": "Креирај",
			"Reset": "Ресетирање",
			"Address is required": "Адресата е задолжителна",
			"Not found": "Не е пронајдено",
			"Selected site is deleted!": "Избраниот сајт е избришан!",
			"The site is added to the company": "Новиот сајт е додаден за компанијата",
			"Please add a site.": "Ве молиме креирајте нов сајт.",
			"Sites/Apps/SplitPay receivers": "Сајтови/Апликации/SplitPay",
			"Add a new site/app/split-pay identifier": "Додај нов Сајт/Апликација/SplitPay",
			"Add a new POS (site or app or split-pay identifier)...": "Додај нов POS (Сајт/Апликација/SplitPay)",
			"Add a POS for": "Додадете POS за",
			"Save": "Зачувај",
			"Cancel": "Откажи",
			"Site Details": "Детали за сајтот",
			"Return to site list": "Оди на листата на сајтови",
			"Site Secret": "Таен клуч на сајтот",
			"Site Key": "Клуч на сајтот",
			"ReGenerate": "РеГенерирај",
			"Action": "Акции",
			"Payment Method Name": "Име на методот на плаќање",
			"Payment Methods": "Начини на плаќање",
			"Add PaymentMethod": "Додадете метод на плаќање",
			"Selected Payment Methods is added correctly": "Избраните начини на плаќање се додадени правилно",
			"New Payment Methods": "Нови начини на плаќање",
			"Payments": "Плаќања",
			"Payment Method": "Начин на плаќање",
			"Type": "Тип",
			"Status": "Статус",
			"Transactions": "Трансакции",
			"Transactions Details": "Детали за трансакцијата",
			"Name": "Име",
			"Amount": "Износ",
			"UID": "UID",
			"Subscriptions": "Претплати",
			"Subscriptions Details": "Детали за претплатите",
			"Payment Methods Details": "Детали за начинот на плаќање",
			"Platform Modules": "Платформски Модули",
			"Ticket": "Барање за поддршка",
			"New Ticket": "Ново барање",
			"My Tickets": "Мои барања",
			"Help": "Помош",
			"Link": "Линк",
			"Description": "Опис",
			"Home": "КОНТРОЛНА ТАБЛА",
			"Logout": "Одјави се",
			"Don’t have an account?": "Немате налог?",
			"Seamless site payments | Shipping Automation | Fiscalization": "Универзален платен систем за правни субјекти",
			"Email must be a valid email address": "Адресата на е-пошта мора да биде валидна адреса на е-пошта",
			"Email is required": "Е-поштата е задолжителна",
			"Password is required": "Лозинката е неопходна",
			"Email address": "Адреса на е-пошта",
			"Password": "Лозинка",
			"Repeat password" : "Потврда на лозинка",
			"Remember me": "Запамти ме",
			"Forgot password?": "Заборавивте лозинка?",
			"Login": "Пријави се",
			"Sign In FaceBook": "Facebook пријава",
			"Sign In Google": "Google пријава",
			"Already have an account?": "Веќе имате налог?",
			"Seamless site payments | Shipping Automation | Fiscalization": "Беспрекорен прием на уплати на сајтот | Автоматизација на достава | Фискализација",
			"OR": "ИЛИ",
			"First name required": "Името е задолжително",
			"Last name required": "Презимето е задолжително",
			"First name": "Име",
			"Last name": "Презиме",
			"Register": "Регистрирај се",
			"Logo upload": "Отпремање на лого",
			"Localizations": "Локализации",
			"PAYMENT METHOD LOCALIZATIONS": "ЛОКАЛИЗАЦИИ НА МЕТОДИТЕ НА ПЛАЌАЊЕ",
			"Add Payment Method Localizations": "Додадете локализација на методот на плаќање",
			"Search data...": "Пребарај податоци...",
			"Localization": "Локализација",
			"Localization Details": "Детали за локализацијата",
			"User": "Корисник",
			"Ticket Name": "Наслов на барањето за поддршка",
			"Ticket Details": "Детали за барањето за поддршка",
			"Mark Resolved" : "Означете како решено",
			"Send" : "Испрати",
			"Delete company":"Бришење на компанија",
			"Please confirm you want to delete company": "Ве молиме потврдете го бришењето на компанијата",
			"You might lose important data releted to this company.": "Може да изгубите важни податоци поврзани со оваа компанија.",
			"Selected Company": "Избрана Компанија",
			"Selected Site": "Избран Сајт",
			"Re-generate": "Повторно генерирај",
			"--company not selected--": "--компанија не е избрана--",
			"--site/app/split-pay not selected--": "--не е избран сајт/апликација/сплит-пеј--",
			"COMPANY_NAME_EXISTS": "Веќе постои регистрирана компанија со ова име! Побарајте од корисникот кој ја креирал компанијата да ви одобри пристап!",
			"SITE_EXISTS": "Овој сајт е веќе регистриран! Побарајте од корисникот кој го креирал сајтот да ви одобри пристап!",
			"Site Url": "URL на сајт",
			"Selected POS": "Избран ПОС",
			"Please Select POS": "Ве молиме изберете ПОС",
			"SITES/APPS/SplitPay-s": "САЈТОВИ/АПЛИКАЦИИ/СплитПеј",
			"FISCAL/INTEGRATION MODULES": "ФИСКАЛНИ/ИНТЕГРАЦИОНИ МОДУЛИ",
			"SHIPPING METHODS": "МЕТОДИ НА ИСПОРАКА",
			"ORDERS": "НАРАЧКИ",
			"SUPPORT TICKETS": "БАРАЊА ЗА ТЕХНИЧКА ПОДДРШКА",
			"Loading...": "Се вчитува...",
			"From date/time": "Од датум/време",
			"To date/time": "До датум/време",
			"search...": "пребарувај...",
			"Load": "Вчитај",
			"Close": "Затвори",
			"Uid": "Уид",
			"Ident.": "Идент.",
			"Order ident.": "Идент. на нарачка",
			"Created On": "Создадено на",
			"Customer": "Купувач",
			"Payment": "Плаќање",
			"Split pay": "Сплит пеј",
			"Nothing found": "Ништо не е пронајдено",
			"Fiscal/integration methods": "Фискални/интеграциони методи",
			"Fiscal/integration Methods": "Фискални/интеграциони методи",
			"Add New Fiscal/Integration Method": "Додај нов фискален/интеграционен метод",
			"Fiscal/integration method": "Фискален/интеграционен метод",
			"Enabled": "Овозможено",
			"List order": "Редослед на листа",
			"Fallback Of": "Резервна опција на",
			"Order": "Нарачка",
			"Payment methods": "Методи на плаќање",
			"Add New Payment Method": "Додај нов метод на плаќање",
			"No": "Не",
			"POS uri": "ПОС ури",
			"Additional url-s": "Дополнителни URL-адреси",
			"Merchant POS uid": "УИД на ПОС на трговецот",
			"HOLESTKey exp.": "Истек на HOLESTKey",
			"Web site": "Веб-страница",
			"IOS/Android App": "iOS/Android апликација",
			"Stand alone split-pay receiver": "Самостоен приемник за сплит-пеј",
			"POS: Sites or Apps or SplitPay receivers": "ПОС: Сајтови или апликации или приемници за сплит-пеј",
			"Add a new POS": "Додај нов ПОС",
			"POS type": "Тип на ПОС",
			"comma separated, without www. and https://": "одделени со запирка, без www. и https://",
			"sandbox: key not needed": "песок: клучот не е потребен",
			"Yes": "Да",
			"Create new company...": "Креирај нова компанија...",
			"Administrator": "Администратор",
			"Staff": "Персонал",
			"Back to companies list...": "Назад кон листата на компании...",
			"Company users": "Корисници на компанијата",
			"Telephone": "Телефон",
			"Role": "Улога",
			"Actions": "Акции",
			"Add user": "Додај корисник",
			"Add access for a user": "Додај пристап за корисник",
			"Add a user access for the company": "Додај пристап на корисник за компанијата",
			"Make sure that a user you are adding access for has an HolestPay account. If not please instruct him/her to create a account first.": "Проверете дали корисникот на кој му додавате пристап има HolestPay сметка. Ако нема, замолете го/ја прво да креира сметка.",
			"Email": "Е-пошта",
			"Remove access": "Отстрани пристап",
			"HPay Support": "HPay поддршка",
			"Yourself": "Вие самите",
			"Payment Method Configuration": "Конфигурација на метод на плаќање",
			"Back to site payment methods...": "Назад кон методите на плаќање на сајтот...",
			"LOCALIZATION": "ЛОКАЛИЗАЦИЈА",
			"[DEFAULT]": "[СТАНДАРДНО]",
			"Add": "Додај",
			"Language code": "Код на јазик",
			"2 letters code": "Код од 2 букви",
			"Upload": "Постави",
			"Shipping methods": "Методи на испорака",
			"Add New Shipping Method": "Додај нов метод на испорака",
			"Shipping Method": "Метод на испорака",
			"Subscription details...": "Детали за претплата...",
			"Orders": "Нарачки",
			"Shipping": "Испорака",
			"Fiscal": "Фискализација",
			"Support tickets": "Барања за поддршка",
			"Create a new support ticket": "Креирај ново барање за поддршка",
			"Date/Time": "Датум/Време",
			"Issue description": "Опис на проблемот",
			"Raised by": "Покренато од",
			"Concerning company": "Во врска со компанија",
			"Concerning POS": "Во врска со ПОС",
			"Open": "Отвори",
			"Support ticket": "Барање за поддршка",
			"Ticket time": "Време на барање",
			"Back to support tickets list...": "Назад кон листата на барања за поддршка...",
			"SUBJECT": "НАСЛОВ",
			"Delete Support Ticket": "Избриши барање за поддршка",
			"Message/Issue Description": "Опис на порака/проблем",
			"at": "во",
			"Delete message": "Избриши порака",
			"Select payment method to add...": "Изберете метод на плаќање за додавање...",
			"Payment Type": "Тип на плаќање",
			"Info": "Информации",
			"Recurring support": "Поддршка за повторливи плаќања",
			"Is instant?": "Дали е инстантно?",
			"HPay cardform": "HPay формулар за картичка",
			"Known banks/financial institutions": "Познати банки/финансиски институции",
			"Intesa, AIK, Komercijalna(NLB), HALKBANK": "Интеза, АИК, Комерцијална(НЛБ), ХАЛКБАНК",
			"Bilo koja banka u Republici Serbiji": "Било која банка во Република Србија",
			"OTP, Raiffeisen, Unicredit ": "OTP, Рајфајзен, Уникредит",
			"ALT5PAY": "ALT5PAY",
			"Unos kartice na samom sajtu i COF subskripcije": "Внесување на картичка на самиот сајт и COF претплати",
			"Requirements": "Барања",
			"E-commerce account with supported bank. Account type must be 3D+API": "Е-трговска сметка со поддржана банка. Типот на сметката мора да биде 3D+API",
			"ADD": "ДОДАЈ",
			"Generisanje QR koda za placanje iz aplikacije i ekvivalatne uplatnice": "Генерирање QR код за плаќање од апликација и еквивалентна уплатница",
			"Racun u bilo kojoj banci u Srbiji": "Сметка во било која банка во Србија",
			"E-commerce account with supported bank": "Е-трговска сметка со поддржана банка",
			"Mogucnost placanja kriptovalutama za ino kupce. Vi mozete dobiti samo pravi USD|EUR|CAD nikako kriptovalutu (taj deo je u nadleznosti Alt5Pay-a). Zato nije potrebna nikakva poseba licenca i po zakonu ove uplate se sagledavaju na isti naćin kao i PayPal.": "Можност за плаќање со криптовалути за странски купувачи. Вие можете да добиете само вистински USD|EUR|CAD, никако криптовалута (тој дел е во надлежност на Alt5Pay). Затоа не е потребна никаква посебна лиценца и според законот овие уплати се разгледуваат на ист начин како и PayPal.",
			"Morate imati otvoren nalog na alt5pay.com. Morate imati EUR|USD račun da bi mogli da dovučete uplate. ": "Морате да имате отворен налог на alt5pay.com. Морате да имате EUR|USD сметка за да можете да повлечете уплати.",
			"Change password": "Промена на лозинка",
			"Pick a new password of at least 8 characters, at least one uppercase letter and at least one number": "Изберете нова лозинка од најмалку 8 знаци, најмалку една голема буква и најмалку еден број",
			"New password": "Нова лозинка",
			"Repeat new password": "Повтори нова лозинка",
			"Add Biometric/WebAuthN Account Verification": "Додај Биометриска/WebAuthN верификација на налог",
			"Face-Id and fingerprint are biometric credentials and they provide maximal level of reliability for in the process of your identity verification. It's recommended that you use biometric methods when available. Every browser, even on old PC/phone, will enable you to use basic PIN verification.": "Face-Id и отпечаток од прст се вистински биометриски акредитиви и обезбедуваат максимално ниво на сигурност во процесот на верификација на вашиот идентитет. Се препорачува да користите биометриски методи кога се достапни. Секој прелистувач, дури и на стар компјутер/телефон, ќе ви овозможи да користите основна верификација со PIN.",
			"Verification device selection": "Избор на уред за верификација",
			"Use current browser": "Користи го тековниот прелистувач",
			"Scan this QR code with your mobile phone in order of using your phone as external verification device": "Скенирајте го овој QR код со вашиот мобилен телефон за да го користите вашиот телефон како надворешен уред за верификација",
			"Equivalent url": "Еквивалентна URL адреса",
			"DISCLAMER": "ОДРЕКУВАЊЕ ОД ОДГОВОРНОСТ",
			"does not store or transmit": "не складира или пренесува",
			"any of your biometric data like Face-Id or Fingerprint. This data is contained in your device OS, and process of verification itslelf is done by your device/PC. Upon successful verification your device will inform our system that your identity is confirmed by providing matching cryptographic signatures.": "ниту еден од вашите биометриски податоци како Face-Id или отпечаток од прст. Овие податоци се содржани во оперативниот систем на вашиот уред, а самиот процес на верификација се врши од вашиот уред/компјутер. По успешна верификација, вашиот уред ќе го информира нашиот систем дека вашиот идентитет е потврден со обезбедување соодветни криптографски потписи.",
			"Account settings": "Поставки на сметка",
			"Back to dashboard...": "Враќање на почетната страница...",
			"Add Biometric (Fingerprint, Face-Id or PIN) verification": "Додај Биометриска (Отпечаток од прст, Face-Id или PIN) верификација",
			"Biometric/WebAuthN credentials": "Биометриски/WebAuthN акредитиви",
			"Device type": "Тип на уред",
			"OS": "ОС",
			"Browser": "Прелистувач",
			"No biometric/webauthn credetials exists. When identity verification is required HolestPay will revert method of sending you 6 digit security code over your e-mail. It's recommended thet you use more modern and secure biometric/webauthn methods since they don't involve trasmition of security parameters in clear form over channels that don't guarantee absolute security.": "Не постојат биометриски/WebAuthN акредитиви. Кога е потребна верификација на идентитет, HolestPay ќе го врати методот на испраќање 6-цифрен безбедносен код преку вашата е-пошта. Се препорачува да користите посовремени и побезбедни биометриски/WebAuthN методи бидејќи тие не вклучуваат пренос на безбедносни параметри во јасна форма преку канали кои не гарантираат апсолутна безбедност.",
			"Passwords do not match": "Лозинките не се совпаѓаат",
			"Please enter correct email and password": "Ве молиме внесете точна е-пошта и лозинка",
			"No such user or bad password": "Не постои таков корисник или погрешна лозинка",
			"Service is currently unavailable. Please try later...": "Услугата е моментално недостапна. Ве молиме обидете се подоцна...",
			"Handshake session expired or not found": "Сесијата за ракување истече или не е пронајдена",
			"Data mismatch! Please retry registration from beginning!": "Несовпаѓање на податоци! Ве молиме обидете се повторно со регистрација од почеток!",
			"Password must meet complexity requirements": "Лозинката мора да ги задоволува критериумите за комплексност",
			"Password confirmation mismatch": "Несовпаѓање на потврда на лозинка",
			"You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!": "Привремено сте блокирани поради голем број на барања во краток временски период. Обидете се за неколку минути!",
			"Access recovery": "Враќање на пристап",
			"Password reset": "Ресетирање на лозинка",
			"Enter your email, set a new password and confirm it. Then proceed to the identity verification.": "Внесете ја вашата е-пошта, поставете нова лозинка и потврдете ја. Потоа продолжете на чекорот за верификација на идентитет.",
			"Proceed to identity verification...": "Продолжи на верификација на идентитет...",
			"Remember me on this browser": "Запамти ме на овој прелистувач",
			"Upload blocked!": "Поставувањето е блокирано!",
			"Site payment method data saved!": "Податоците за начинот на плаќање на сајтот се зачувани!",
			"Updated!": "Ажурирано!",
			"Please set a company logo!": "Ве молиме поставете лого на компанијата!",
			"Please enter at least 8 characters": "Ве молиме внесете најмалку 8 знаци",
			"Please enter at least 1 number": "Ве молиме внесете најмалку 1 број",
			"Please enter at least 1 lowercase letter": "Ве молиме внесете најмалку 1 мала буква",
			"Please enter at least 1 uppercase letter": "Ве молиме внесете барем една голема буква",
			"Please enter at least 1 special character": "Ве молиме внесете барем еден специјален карактер",
			"Transaction details...": "Детали за трансакцијата...",
			"ACTIONS": "АКЦИИ",
			"Support Ticket Details": "Детали за барањето за поддршка",
			"This support ticket is resolved": "Ова барање за поддршка е решено",
			"enter valid url": "внесете валидна URL адреса",
			"enter url": "внесете URL адреса",
			"Auxiliary site url-s": "Помошни URL-адреси на сајтот",
			"NOTE: for registered sites and apps you can use site UID as split-pay identifier. You need a stand alone split-pay POS only when you don't have your site/app and you need to receive payments only through purhases over your partner's site(s).": "НАПОМЕНА: за регистрирани сајтови и апликации можете да го користите UID-от на сајтот како идентификатор за сплит-пеј. Потребен ви е самостоен ПОС за сплит-пеј само кога немате свој сајт/апликација и треба да примате плаќања само преку купувања преку сајтот/сајтовите на вашиот партнер.",
			"POS Configuration": "ПОС Конфигурација",
			"type": "тип",
			"Back to sites list...": "Враќање на листата на сајтови...",
			"URI/Indentifier": "URI/Идентификатор",
			"Additional Site Url-s (one per line)": "Помошни URL-адреси на сајтот (една по линија)",
			"additional site url-s (one per line)": "помошни URL-адреси на сајтот (една по линија)",
			"Merchant POS UID": "UID на ПОС на трговецот",
			"POS Secret Key": "Таен клуч на ПОС",
			"Prefered exchange rate source": "Префериран извор на курсна листа",
			"Exchange rate correction % (middle)": "Корекција на среден курс во %",
			"HOLEST Key (purchase key)": "HOLEST Клуч (се добива при купување)",
			"Key expires at": "Клучот истекува на",
			"Shipping Method Configuration": "Конфигурација на метод на испорака",
			"Back to site shipping methods...": "Враќање на листата на методи на испорака...",
			"Fallback of": "Резервна опција на",
			"none": "ништо",
			"Order details...": "Детали за нарачката...",
			"Raise a new support ticket": "Креирај ново барање за поддршка",
			"Support tickets are way to make sure our support receives your support request (since email can be unreliable sometimes). Please allow our team at least 24h to respond.": "Барањата за поддршка се начин да се осигурате дека нашата поддршка ќе го добие вашето барање (бидејќи е-поштата понекогаш може да биде несигурна). Ве молиме дозволете му на нашиот тим најмалку 24 часа да одговори.",
			"You will be able to describe your problem after ticket creation.": "Ќе можете да го опишете вашиот проблем по креирањето на барањето.",
			"Please name your issue": "Ве молиме насловете го вашиот проблем",
			"Concerning Company": "Во врска со компанијата",
			"Fiscal/integration method configuration": "Конфигурација на фискален/интеграционен метод",
			"Back to site fiscal/integration methods...": "Врати се на листата на фискални/интеграциони методи...",
			"Select shipping method to add...": "Изберете фискален метод за додавање...",
			"Select fiscal/integration method to add...": "Изберете фискален/интеграционен метод за додавање...",
			"Please enter valid email address": "Ве молиме внесете валидна адреса на е-пошта",
			"You can close this window": "Можете да го затворите овој прозорец/страница",
			"Identity verification required": "Потребна е верификација на идентитет",
			"Biometric/WebAuthN verification device selection": "Избор на уред за биометриска/WebAuthN верификација",
			"Scan this QR code with your mobile phone if you are using your phone as external verification device": "Скенирајте го овој QR код со вашиот мобилен телефон ако го користите телефонот како надворешен уред за верификација",
			"Try different identity verification method...": "Обидете се со друг метод за верификација на идентитет...",
			"E-mail address verification pending...": "Се чека на потврда на адресата на е-пошта преку код од е-пошта...",
			"Please check your mailbox for 6-digit security code we sent you. Don't forget to check SPAM/Trash/Junk folders also if you don't find a mail message containing security code in your primary inbox.": "Проверете го вашето поштенско сандаче за 6-цифрениот безбедносен код што ви го испративме. Не заборавајте да ги проверите и папките SPAM/Trash/Junk ако не најдете е-пошта со безбедносен код во вашето главно поштенско сандаче.",
			"SECURITY CODE": "БЕЗБЕДНОСЕН КОД",
			"Cancel and return to the registration form...": "Откажи и врати се на формуларот за регистрација...",
			"Account data": "Податоци за сметка",
			"Biometric/WebAuthN Identity Protection": "Биометриска/WebAuthN заштита на идентитет",
			"Recommended": "Препорачано",
			"Upon registration setup biometric/webauthn credentials": "По регистрацијата, поставете биометриски/webauthn акредитиви (отпечаток од прст, face-id...)",
			"Password updated": "Лозинката е променета",
			"Error - password refused": "Грешка - лозинката е одбиена",
			"Error - in password update request": "Грешка во барањето за промена на лозинка",
			"Error - could not update the password": "Грешка - лозинката не можеше да биде променета",
			"Data updated": "Податоците се ажурирани",
			"Error - could not update the data": "Грешка - податоците не можеа да бидат променети",
			"Error - refused": "Грешка - барањето е одбиено",
			"Error - in request": "Грешка во барањето",
			"Error - could not obtain credential parameters": "Грешка - параметрите на акредитивите не се достапни",
			"Credentials over external device added successfully": "Акредитивите преку надворешен уред се успешно додадени",
			"Could not obtain credentials!": "Грешка - акредитивите не се достапни!",
			"Could not load payment methods definition": "Дефинициите на начините на плаќање не се вчитани!",
			"Could not load shipping methods definition": "Дефинициите на методите на испорака не се вчитани!",
			"Could not load fiscal/integration methods definition": "Дефинициите на фискалните/интеграционите методи не се вчитани!",
			"Error during data loading!": "Грешка при вчитувањето на податоците!",
			"Please select a POS!": "Ве молиме изберете ПОС!",
			"You must select a company so you could raise a new ticket concerning it": "Мора да изберете компанија за да можете да поднесете ново барање во врска со неа",
			"Message deleted": "Пораката е избришана",
			"Error: message could not be deleted!": "Грешка: пораката не можеше да се избрише!",
			"Error: ticket could not be closed!": "Грешка: барањето не можеше да се затвори!",
			"Error loaging support ticket list": "Грешка при вчитувањето на листата на барања за поддршка",
			"Error during site delete": "Грешка при бришењето на сајтот",
			"Error during POS delete": "Грешка при бришењето на ПОС",
			"Please correct your input!": "Исправете го вашиот влез!",
			"The POS is added successfully!": "ПОС е успешно додаден!",
			"Error during POS add": "Грешка при додавањето на ПОС",
			"Please add a POS - Site, App or SplitPay.": "Додадете ПОС - Сајт, Апликација или SplitPay.",
			"Error during data loading": "Грешка при вчитувањето на податоците",
			"Shipping method is deleted": "Начинот на испорака е избришан",
			"Error: Shipping method could not be deleted!": "Грешка: начинот на испорака не можеше да се избрише!",
			"Error loading POS shipping methods!": "Грешка при вчитувањето на ПОС методи на испорака!",
			"Site shipping method data saved!": "Податоците за начинот на испорака на сајтот се зачувани!",
			"Error saving site shipping method data": "Грешка при зачувувањето на податоците за начинот на испорака на сајтот",
			"Bad language code. Please use 2-letters code, optionally with extension -cyr!": "Лош код на јазик. Ве молиме користете код од 2 букви, опционално со екстензија -cyr!",
			"Error loading site shipping method data": "Грешка при вчитувањето на податоците за начинот на испорака на сајтот",
			"Please select site...": "Ве молиме изберете сајт...",
			"Payment method is deleted": "Начинот на плаќање е избришан",
			"Error: Payment method could not be deleted!": "Грешка: Начинот на плаќање не можеше да се избрише!",
			"Error loading site payment methods!": "Грешка при вчитувањето на начините на плаќање на сајтот!",
			"Error saving site payment method data": "Грешка при зачувувањето на податоците за начинот на плаќање на сајтот",
			"Error during support ticket creation": "Грешка при креирањето на тикет за поддршка",
			"Please Select Company": "Ве молиме изберете компанија",
			"Fiscal/integration method is deleted": "Фискалниот/интеграциониот метод е избришан",
			"Error: fiscal/integration method could not be deleted!": "Грешка: Фискалниот метод не можеше да се избрише!",
			"Error loading site fiscal/integration methods!": "Грешка при вчитувањето на фискалните методи на сајтот!",
			"Site fiscal/integration method data saved!": "Податоците за фискалниот метод на сајтот се зачувани!",
			"Error saving site fiscal/integration method data": "Грешка при зачувувањето на податоците за фискалниот метод на сајтот",
			"Error loading site fiscal/integration method data": "Грешка при вчитувањето на податоците за фискалниот/интеграциониот метод на сајтот",
			"Error during adding of this shipping method!": "Грешка при додавањето на овој начин на испорака!",
			"Error: could not add this shipping method at the moment!": "Грешка: во моментов не е можно да се додаде овој начин на испорака!",
			"The Shipping Methods is exist in this site": "Методите на испорака постојат на овој сајт",
			"Error during adding of this payment method!": "Грешка при додавањето на овој начин на плаќање!",
			"Error: could not add this payment method at the moment!": "Грешка: во моментов не е можно да се додаде овој начин на плаќање!",
			"Error during adding of this fiscal/integration method!": "Грешка при додавањето на овој фискален/интеграционен метод!",
			"Error: could not add this fiscal/integration method at the moment!": "Грешка: во моментов не е можно да се додаде овој фискален метод!",
			"The fiscal/integration methods is exist in this site": "Фискалните/интеграционите методи постојат на овој сајт",
			"Please set a company logo": "Поставете лого на компанијата",
			"The company created successfully!": "Компанијата е успешно креирана!",
			"Error during company create": "Грешка при креирањето на компанијата",
			"Please use .jpg or .png image": "Ве молиме користете .jpg или .png слика",
			"Error on data load!": "Грешка при вчитувањето на податоците!",
			"Error on data update!": "Грешка при ажурирањето на податоците!",
			"Error on data delete!": "Грешка при бришењето на податоците!",
			"Error during access removal": "Грешка при отстранувањето на пристапот",
			"User with given email was not found": "Корисник со дадена е-пошта не е пронајден",
			"You can not manage yourself": "Не можете да управувате со себе",
			"Error adding access for user": "Грешка при додавањето пристап за корисник",
			"Error changing user role": "Грешка при промената на улогата на корисникот",
			"External-device identity verification completed!": "Верификацијата на идентитет преку надворешен уред е завршена!",
			"Could not complete identity verification over external-device": "Није могуће завршити верификацију идентитета преко спољног уређаја",
			"Biometric/WebAuthN verification failed. You can try other verfication method...": "Биометријска/WebAuthN верификација није успела. Можете испробати други метод верификације...",
			"Could not obtain credentials! Try reverting to verification over e-mailed security code...": "Није могуће добити акредитиве! Покушајте да се вратите на верификацију преко безбедносног кода послатог е-поштом...",
			"Biometric/WebAuthN credentials approval on external device...": "Одобравање биометријских/WebAuthN акредитива на спољном уређају...",
			"Biometric/WebAuthN authorization over an external device...": "Биометријска/WebAuthN ауторизација преко спољног уређаја...",
			"Non-valid/unrecognised request!": "Неважећи/непрепознат захтев!",
			"New order": "Нова наруџбина",
			"New subscription": "Нова претплата",
			"Please add a POS.": "Молимо додајте ПОС.",
			"Identity verification": "Верификација идентитета",
			"Error! Please try again or later...": "Грешка! Покушајте поново или касније...",
			"Could not perform Biometric/WebAuthN verification!": "Није могуће извршити биометријску/WebAuthN верификацију!",
			"Account data is accepted. You must verify your email address before we finalize you account creation. Please check you email for security code we sent you.": "Подаци о налогу су прихваћени. Морате да верификујете своју адресу е-поште пре него што завршимо креирање вашег налога. Проверите своју е-пошту за безбедносни код који смо вам послали.",
			"Bad response please retry or try later...": "Лош одговор, покушајте поново или покушајте касније...",
			"first name is required": "име је обавезно",
			"last name is required": "презиме је обавезно",
			"email is required": "е-маил је обавезан",
			"password is required": "приступна шифра је обавезна",
			"confrimation of password is required": "потребна је потврда лозинке",
			"Customer support email": "Ваш е-маил за подршку купцима",
			"Customer support telephone": "Ваш телефон за подршку купцима",
			"Customer support notes": "Ваше напомене везано за подршку купцима",
			"enter notes about your customer support": "унесите напомене везано за подршку купцима",
			"Your payment terms link": "Линк до Ваше стране са условима куповине",
			"HolestPay": "HolestPay",
			"Remove biometric/webauthn credential": "Уклоните биометријске/вебаутхн акредитиве",
			"Please confirm you want remove credential": "Потврдите да желите да уклоните акредитиве",
			"Remove": "Уклони",
			"Delete support ticket message": "Избришите поруку за подршку",
			"Please confirm you want remove message": "Потврдите да желите да уклоните поруку",
			"Mark resolved": "Означи као разрешено",
			"Please confirm you want mark this ticket as resolved": "Потврдите да желите да означите овај захтев као решен",
			"Close ticket": "Затвори захтев",
			"Closed": "Затворено",
			"Issue": "Опис проблема/Питање",
			"IOS/Android bundle id-s - comma separated": "ID-јеви идентификатора апликације за iOS/Android - одвојени зарезима",
			"Idenificator like 'install-air-conditioner' or 'donation-j.doe' or 'joes-currier'": "Идентификатор попут „инсталација-клима-уређаја“ или „donation-j.doe“ или „пера-испорука-и-монтаза“",
			"Delete site": "Обриши сајт",
			"Please confirm you want to delete site": "Потврдите да желите да избришете сајт",
			"You might lose important data releted to this site.": "Можда ћете изгубити важне податке који се односе на овај сајт.",
			"not needed for the sandbox mode": "није потребно за режим sandbox-а",
			"key you bought on ecommerce.holest.com": "кључ који сте купили на https://ecommerce.holest.com",
			"Regenerate secret key": "Регенеришите тајни кључ",
			"Please confirm you want to regenerate POS secret key": "Потврдите да желите да поново генеришете ПОС тајни кључ",
			"You will lose connection with this POS untill you update secret key with it.": "Изгубићете везу са овим ПОС док не ажурирате тајни кључ на њему.",
			"Remove shipping method": "Уклоните начин испоруке",
			"Please confirm you want remove shipping method": "Потврдите да желите да уклоните начин испоруке",
			"You may lose important data by deleting this method.": "Можете изгубити важне податке брисањем овог метода.",
			"Remove localization": "Уклоните локализацију",
			"Please confirm you want remove localization": "Потврдите да желите да уклоните локализацију",
			"No selected": "Није изабрано",
			"Remove payment method": "Уклоните начин плаћања",
			"Please confirm you want remove payment method": "Потврдите да желите да уклоните начин плаћања",
			"Remove fiscal/integration method": "Уклоните фискални/интеграциони метод",
			"Please confirm you want remove fiscal/integration method": "Потврдите да желите да уклоните фискални/интеграциони метод",
			"access to": "приступ",
			"Set role: staff": "Рола -> особље",
			"Set role: admin": "Рола -> админ",
			"Set role: owner": "Рола -> власник",
			"Please confirm you want change role of": "Молимо потврдите промену роле за",
			"to":"у",
			"Set new role":"Постави нову ролу",
			"Only one user can be POS 'creator' therefore current 'creator' will became 'admin'.": "Само један корисник може бити 'креатор' ПОС-а, стога ће тренутни 'креатор' постати 'админ'.",
			"Error during changing of ther user role": "Грешка при промени роле налога за ПОС",
			"Change POS user role": "Промена роле налога за ПОС",
			"staff": "особље",
			"admin": "админ",
			"creator": "власник",
			"hpay-support":"hpay подршка",
			"Remove user access": "Уклоните приступ корисника",
			"Please confirm you want to remove": "Потврдите да желите да уклоните",
			"Remove Access": "Уклоните приступ",
			"Please check you email for security code we sent you.": "Проверите своју е-пошту за безбедносни код који смо вам послали.",
			"Reverting to verification over e-mailed security code...": "Враћање на верификацију преко безбедносног кода послатог е-поштом...",
			"enter valid email": "Унесите исправну е-пошту",
			"Please correct your input": "Исправите свој унос",
			"Bad email address": "Лоша адреса е-поште",
			"Bad first or last name": "Лоше име или презиме",
			"Specified email already exists": "Наведена адреса е-поште већ постоји",
			"too short?": "прекратко?",
			"too long?": "предуго?",
			"Waiting for authorization...": "Чекање на ауторизацију...",
			"Company selection/registration...": "Избор/регистрација фирме...",
			"Site selection/registration...": "Избор/регистрација сајта...",
			"POS was updated correctly!": "ПОС је успешно ажуриран!",
			"Error in site update request!": "Грешка при захтеву ажурирања!",
			"and choose...": "и одабери...",
			"POS type is not an site. You can not connect site to different POS types!": "Одабрани ПОС није типа сајт. Не можете повезати сајт са другим типовима ПОС-а.",
			"Connected!": "Повезано!",
			"ACTIVATED": "АКТИВИРАНО",
			"HOLEST PURCHASE KEY WAS NOT ACCEPTED!": "HOLEST КЉУЧ ПРОИЗВОДА НИЈЕ ПРИХВАЋЕН!",
			"Buy a new key...": "Купи нови кључ...",
			"Fiscal/integration/e-invoice": "Фискал/интеграције/е-фактуре",
			"Site to be connected must be main url or one of auxiliary urls for the selected POS!": "Сајт који се повезује мора бити главни УРИ или један од помоћних УРИ-ја за изабрани ПОС!",
			"bad value!": "лоша вредност!",
			"Shipping Methods": "Методе Испоруке",
			"fiscal/e-invoice tax system integration, embedded devices (like carwash coin machines) or external system notifications (production line intstructions)": "интеграција са пореским системом фискал/е-фактура, нотификације за специјализоване уређаје (попут машина за жетоне код аутоперионица) или нотификација ка другим системима (нпр. пошаљи налог фабрици)",
			"courier systems api integration": "АПИ интеграције са системима курирских служби",
			"banks/financial instutions payments integrations": "интеграције са платим системима банака и других финансијских институција",
			"Instant payment/shipping/fiscal/integation notification url": "Линк за инстант нотификације - плаћање/испорука/фискал/интеграције",
			"Fixed language (for customers)": "Фиксирани језик (за купце)",
			"Payment vault token saving policy (when available)": "Полиса чувања платног токена (кад је доступно)",
			"Leave as POS requested": "Остави како је ПОС захтевао",
			"Never save": "Никад не чувај",
			"Force required": "Постави као обавезно",
			"Method UID": "УИД методе",
			"Process Group": "Процесна група",
			"If multiple methods belong to same process group after one in order generates result rest of them are skipped.": "Уколико више метода припада истој процесној групи, и када једна у редоследу генерише резултат извршење осталих се прескаче",
			"Should be unique! If you need to re-create POS method (because any reason) it's important you keep this value same": "Треба бити уникатна вредност! Уколико морате да ре-креирате методу за ПОС (из било ког разлога) важно је да ова вредност остане иста",
			"ORDER ACTIONS": "АКЦИЈЕ ЗА НАРУЏ.",
			"Show path": "Покажи путању",
			"Go to": "Иди на",
			"Go to first page": "Иди на прву страницу",
			"Go to last page": "Иди на последњу страницу",
			"Go to next page": "Иди на следећу страницу",
			"Go to previous page": "Иди на претходну страницу",
			"page": "страницу",
			"of": "од",
			"Empty": "Празно",
			"Clear": "Бриши",
			"Close": "Затвори",
			"Loading…": "Учитавање…",
			"No options": "Нема опција",
			"Open": "Отвори",
			"Pagination navigation": "Навигација по страницама",
			"Rows per page:": "Редова по страници:",
			"Company Warehouses": "Магацини Фирме",
			"Identifier": "Идентификатор",
			"Postal Code": "Поштански број",
			"Street Name": "Назив улице",
			"Street Number": "Број у улици",
			"important if you use shipping": "битно ако користите модуле за испоруку",
			"Default": "Подразумевано",
			"Disable HPay Notifications": "Искључи HPay Нотификације",
			"Enter current password": "Унесите тренутну лозинку",
			"Current password": "Тренутна лозинка",
			"If your site/POS can handle all customer messaging. Note that some messages are required by law!": "Ако само продајно место може послати све поруке купцима. НАПОМЕНА: неке поруке су обавезне по закону!",
			"Use your own SMTP to send customer notifications": "Користи свој SMTP за емаил нотификације купцима",
			"Enable if you want to deliver HPay customer mails from your SMTP server. If send fails it will revert to HPay SMTP!": "Укључи ако желиш да се HPay нотификације за купце шаљу са твог SMTP сервера. Ако слање не успе мејлови ће бити послати преко HPay SMTP-а",
			"Send test mail to": "Пошаљи тест мејл на адресу",
			"TEST SMTP SEND": "ПОШАЉИ ТЕСТ МЕЈЛ",
			"Enter proper email address!": "Унеси исправну е-маил адресу!",
			"SMTP Password": "SMTP Лозинка",
			"SMTP Username": "SMTP Корисничко Име",
			"SMTP Security Protocol": "SMTP Сигурносни Протокол",
			"SMTP From Name": "SMTP Назив Пошиљаоца",
			"Enter proper SMTP settings!":"Унсите исправна SMTP подешавања!",
			"Error in test mail sending!":"Грешка при слању тест email поруке",
			"Test mail was sent however you must confirm successful reception by checking test address inbox!":"Test email порука је послата али морате такође проверити да је пријем био успешан у inbox-у test mail адресе!",
			"Standard type is for platforms where you install plugin/module like wooCommerce, OpenCart, Magento, etc..": "Стандардни тип је за платформе где инсталирате plugin/modul , нпр. wooCommerce, OpenCart, Magento, итд..",
			"Standard":"Стандардно"
		 }
		
	}
};

HPAY_TRANSLATION.languages = [];


const CYR = "А,а,Б,б,Ц,ц,Ч,ч,Ћ,ћ,Д,д,Џ,џ,Ђ,ђ,Е,е,Ф,ф,Г,г,Х,х,И,и,Ј,ј,К,к,Л,л,Љ,љ,М,м,Н,н,Њ,њ,О,о,П,п,Р,р,С,с,Ш,ш,Т,т,У,у,В,в,З,з,Ж,ж".split(",");
const LAT = "A,a,B,b,C,c,Č,č,Ć,ć,D,d,Dž,dž,Đ,đ,E,e,F,f,G,g,H,h,I,i,J,j,K,k,L,l,Lj,lj,M,m,N,n,Nj,nj,O,o,P,p,R,r,S,s,Š,š,T,t,U,u,V,v,Z,z,Ž,ž".split(",");
const LATCYR = {};
LAT.forEach((l, index) => {
	LATCYR[l] = CYR[index];
});
HPAY_TRANSLATION.transliterate_lat_cyr = (str) => { 
	if(!str) return str; 
	LAT.filter(c => c.length > 1).forEach(c => {
			str = str.replaceAll(c,LATCYR[c]);
		});
	return str.split("").map(l => LATCYR[l] ? LATCYR[l] : l).join("").replaceAll('ХПаy','HPay').replaceAll('ПЦИ ДСС','PCI DSS').replaceAll('ПЦИ ДСС','PCI DSS').replaceAll('ХОЛЕСТ Е-ЦОММЕРЦЕ ДОО','HOLEST E-COMMERCE DOO').replaceAll('ХОЛЕСТ','HOLEST').replaceAll('ХОЛЕСТ','HOLEST');
};
HPAY_TRANSLATION.rs_cyr = {translation:{}};
for(let phrase in HPAY_TRANSLATION.rs.translation){
	if(HPAY_TRANSLATION.rs.translation.hasOwnProperty(phrase)){
		HPAY_TRANSLATION.rs_cyr.translation[phrase] = HPAY_TRANSLATION.transliterate_lat_cyr(HPAY_TRANSLATION.rs.translation[phrase]);
	}
}

for(let lng in HPAY_TRANSLATION){
	if(HPAY_TRANSLATION.hasOwnProperty(lng)){
		if(HPAY_TRANSLATION[lng].translation){
			HPAY_TRANSLATION.languages.push(lng);
		}
	}
}

//Object.assign(exports,HPAY_TRANSLATION);//UNCOMMENT WHEN RUNNING SCAN TRANSLATIONS
export default HPAY_TRANSLATION;
