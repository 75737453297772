import { filter } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { Card, Table, TableRow, TableBody, TableCell, Container, Typography, TableContainer, Button } from '@mui/material';
// import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';

//import configData from "../config.json";
//import { fetchUtils } from 'react-admin';
//import jsonServerProvider from '../data_provider';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import SelecetCompany from './SelecetCompany';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';

//const [, forceUpdate] = useReducer(x => x + 1, 0);

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
	let q = query.toLowerCase();  
    return filter(array, (_item) => {
		if(_item.siteurl.toLowerCase().indexOf(query.toLowerCase()) !== -1){
			return true;
		}
		
		if(String(_item.siteurls).toLowerCase().indexOf(query.toLowerCase()) !== -1){
			return true;
		}
		
		if(String(_item.Id).toLowerCase() == query.toLowerCase()){
			return true;
		}
		
		if(String(_item.merchant_uid).toLowerCase().indexOf(query.toLowerCase()) !== -1){
			return true;
		}
		return false;
	});
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SelectSite({ reload }) {
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('siteurl');
  const [filterName, setFilterName] = useState('');
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(9999);
  const [sites, setsites] = useState([]);
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const [opensite, setopensite] = useState(false);
  const [opencompany, setopencompany] = useState(false);
  const [initialLoad, setInitialLoad] = useState("...");      
  
  
  
  const { t } = useTranslation();
  const history = useHistory();
  

  const TABLE_HEAD = [
   // { id: 'Id', label: t('ID'), alignRight: false },
    { id: 'siteurl', label: t('Site Url'), alignRight: false },
    { id: 'siteurls', label: t('Additional Site Url-s'), alignRight: false },
    { id: 'companyname', label: t('Company Name'), alignRight: false },
    { id: 'date', label: t('Date'), alignRight: false },
	{ id: 'godetails', label: '', alignRight: true }
	//,{ id: '' }
  ];

  const handleOpenSelect = (params) => {
    setopensite(true);
  }
  const handleClose = () => {
    setopensite(false);
    setopencompany(false);

  };
  
  const handleAddSite = () => {
	window.__hp_add_site = true; 
	history.push('/sites');
	setopensite(false);
  };

  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  const dialogCompany = (params) => {
    setopencompany(true);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sites.map((n) => n.Id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const manage = (Id) => {
    let name = "";
    for (let i = 0; i < sites.length; i++) {
      if (sites[i].Id === Id) {
        name = sites[i].siteurl;
      }
    }
	
	let change = sessionStorage.site_id != Id;
    
	sessionStorage.CurrentSite = JSON.stringify({ id: Id, name: name });
	sessionStorage.prev_site_id = sessionStorage.site_id;
	sessionStorage.site_id = Id;
	
    //loadData()
    
	handleClose();
	
	localStorage["hpay_last_company_id"] = sessionStorage.company_id;
	localStorage["hpay_last_site_id"] = sessionStorage.site_id;
	
	if(change){
		let event = new Event("hpay-site-changed");
		window.dispatchEvent(event);
	}
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sites.length) : 0;

  const filteredSites = applySortFilter(sites, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredSites.length === 0;
  
  const afterLoadCompanies = (companies) => {
	  if(initialLoad){
		  if(companies && companies.length){
			  if(sessionStorage.CurrentCompany){
				 setopencompany(false);
			  }else{
				 setopencompany(false);
			  }
		  }
	  }
	  setInitialLoad("");
  };
  
  const loadData = (params) => {
	  
	if(!window.HPAYCompanies){
		setTimeout(loadData, 1000, params);
		return;
	}	
	  
	if (sessionStorage.company_id) {
	 
	  let res_data = [];
	  let company_id = sessionStorage.company_id;
	  

	  if(window.HPAYCompanies && window.HPAYCompanies[company_id] && window.HPAYCompanies[company_id].HPAYSites){
		 
		 res_data = Object.values( window.HPAYCompanies[company_id].HPAYSites ).map(s => {
			 
			 let role = "staff";
			 
			 if(s.Company && s.Company.UserCompanies && s.Company.UserCompanies[0] && s.Company.UserCompanies[0].Role){
				 role = s.Company.UserCompanies[0].Role;
			 }
			 
			 return {
                Id: s.id,
                siteurl: s.Url,
                siteurls: s.Urls,
                companyname: s.Company.Name,
                date: s.CreatedAt,
				company: s.Company,
				currentuserrole: role,
				merchant_uid: s.MerchantsiteUid
              };
			 
		 });
		 
		 if(!res_data.length){
			 setAlertMessage("Please add a POS.");
             setAlertType("info");
             setAlertOpen(true);
		 }
	  }	
	  
	  setsites(res_data);
	} else {
      setAlertMessage(t("Please select a company"));
      setAlertType("info");
      setAlertOpen(true);
    }
  }
  useEffect(() => {
	
	loadData();
	
	window.addEventListener("hpay-sites-loaded", loadData, false);
	return () => {
		window.removeEventListener("hpay-sites-loaded", loadData, false);
	};
	
  }, [])

  return (
    <>
      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
	  
	  {/*
      <Typography variant="h7" gutterBottom>
        {t('Company')} : {sessionStorage.CurrentCompany ? JSON.parse(sessionStorage.CurrentCompany).name : t('No selected')}
      </Typography>
      <Typography variant="h7" gutterBottom>
        {t('Site')} : {sessionStorage.CurrentSite ? JSON.parse(sessionStorage.CurrentSite || "{}").name : t('No selected')}
      </Typography>
	  */}
	  
	  <Button
        variant="contained"
        onClick={(e) => dialogCompany()}
			/* startIcon={<CheckBoxIcon />} */
        color="secondary"
		className='cmd-company-select'
		label={t('Selected Company')}
      >
        {sessionStorage.CurrentCompany ? JSON.parse(sessionStorage.CurrentCompany).name : t('--company not selected--')}
      </Button>

      <SelecetCompany handleCloseSelector={handleClose} openCompany={opencompany} loadSites={loadData} afterLoad={afterLoadCompanies} />
	  <span>&nbsp;&#xbb;&nbsp;</span>
	  
	  <Button
        variant="contained"
        onClick={(e) => handleOpenSelect()}
		label={t('Selected POS')}
		className='cmd-site-select'
        /* startIcon={<CheckBoxIcon />} */
        color="secondary"
      >
       {sessionStorage.CurrentSite ? JSON.parse(sessionStorage.CurrentSite || "{}").name : t('--not selected--')}
	   {initialLoad}
	  </Button>
	  
      <Dialog open={opensite} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>{t('Please Select POS')}</DialogTitle>
        <DialogContent>
          <Container>
            <Card style={{ boxShadow: "none" }}>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
              
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={sites.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      //onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredSites
                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, key) => {
                          const { Id, siteurl, siteurls, companyname, date } = row;
                          const isItemSelected = sessionStorage.site_id == Id;
							

							
                          return (
						  
                            <TableRow
                              hover
                              key={key}
                              tabIndex={-1}
                              //role="checkbox"
                              onClick={(e) => manage(Id)}
                              selected={isItemSelected}
							  className='clickable'
                              aria-checked={isItemSelected}
                            >
							{/*
							 <TableCell align="left">{Id}</TableCell>
							*/}
                              <TableCell align="left">
                               {siteurl}
                              </TableCell>
                              <TableCell align="left">{siteurls}</TableCell>
                              <TableCell align="left">{companyname}</TableCell>
                              <TableCell align="left">{date}</TableCell>
							  <TableCell align="right">
							  
							  <TableCell align="right">
									
								<Button
									variant="light"
									onClick={() => {
										manage(Id)
										setTimeout(() => {
											history.push("/sitedetails/" + Id);	
										},300);
									}}
									component={RouterLink}
									to="#"
								  >
									{t("Details...")}
								</Button> 
								
								</TableCell>
								
							  </TableCell>
							  {/*
                              <TableCell align="right">
                                <UserMoreMenu />
                              </TableCell>
							  */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
			  
            </Card>
			<br/>
		  
			
			{(sessionStorage.__loading_user_companies == 1 || sessionStorage.__loading_user_company_sites == 1) ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
          </Container>
        </DialogContent>
		<DialogActions>
           <Container>
			   <Typography variant="paragraph" align="center" paragraph={true}>
					<Button
						
						variant="dark"
						onClick={handleAddSite}
						component={RouterLink}
						to="#"
						startIcon={<AddTaskIcon />}
						style={{background:"#f6f7df"}}
					  >
						{t("Add a new POS (site or app or split-pay identifier)...")}
					  </Button>
				</Typography>
		    </Container>
        </DialogActions>
      </Dialog>

    </>
  );
}
